import styled from '@emotion/styled'
import React from 'react'
import theme from '../../../theme/theme'

interface PaperProps {
  children: React.ReactNode | JSX.Element | string | undefined
  type?: 'card' | 'default'
  padding?: string
  margin?: string
  variant?: 'default' | 'error' | 'gradient'
  borderWidth?: string
  borderColor?: string
  backgroundColor?: string
  borderRadius?: string
  boxShadow?: string
  height?: string
}

const Wrapper = styled.div<{
  type?: 'card' | 'default'
  padding?: string
  margin?: string
  variant?: 'default' | 'error' | 'gradient'
  borderWidth?: string
  borderColor?: string
  backgroundColor?: string
  borderRadius?: string
  boxShadow?: string
  height?: string
}>`
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  box-shadow: ${({ boxShadow, type }) =>
    boxShadow
      ? boxShadow
      : type === 'card'
      ? '4px 4px 16px rgba(0, 0, 0, 0.15)'
      : ''};
  border-radius: ${({ type, borderRadius }) =>
    borderRadius ? borderRadius : type === 'card' ? '4px' : '0px'};
  border: ${({ variant, borderWidth }) =>
      borderWidth ? borderWidth : variant === 'gradient' ? '3px' : '1px'}
    solid
    ${({ variant, borderColor }) =>
      borderColor
        ? borderColor
        : variant === 'gradient'
        ? theme.colors.primary
        : variant === 'error'
        ? theme.colors.errorBorder
        : theme.colors.borderColor};
  background: ${({ variant, backgroundColor }) =>
    backgroundColor
      ? backgroundColor
      : variant === 'gradient'
      ? 'linear-gradient(90deg, #DFECFF 1.07%, rgba(242, 247, 254, 0) 110.85%);'
      : variant === 'error'
      ? theme.colors.secondaryBack
      : theme.colors.white};
`

const Paper: React.FC<PaperProps> = ({
  children,
  type = 'default',
  variant = 'default',
  padding,
  margin,
  borderWidth,
  borderColor,
  backgroundColor,
  borderRadius,
  boxShadow,
  height,
}) => {
  return (
    <Wrapper
      type={type}
      padding={padding}
      margin={margin}
      variant={variant}
      borderWidth={borderWidth}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      height={height}
    >
      {children}
    </Wrapper>
  )
}

export default Paper
