import styled from '@emotion/styled'
import React from 'react'
import theme from '../../../theme/theme'
import Notification from './Notification'

const MainContainer = styled.div`
  width: 350px;
`
const Header = styled.div`
  padding: 20px;
  padding-bottom: 10px;
  color: ${theme.colors.primary};
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid ${theme.colors.borderColor};
`

const ClearNotification = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${theme.colors.borderColor};
  span {
    font-size: 14px;
    text-decoration: underline;
    color: ${theme.colors.primary};
    font-weight: 600;
    cursor: pointer;

    &:hover {
      color: ${theme.colors.secondary};
    }
  }
`

const NotificationWrapper = styled.div`
  max-height: 400px;
  overflow-y: auto;
`

const NotificationDropdown = () => {
  return (
    <MainContainer>
      <Header>Notifications</Header>
      <NotificationWrapper>
        <Notification />
        <Notification />
        <Notification />
        <Notification />
        <Notification />
      </NotificationWrapper>
      <ClearNotification>
        <span>Clear Notifications</span>
      </ClearNotification>
    </MainContainer>
  )
}

export default NotificationDropdown
