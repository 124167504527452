import React from 'react'
import Paper from '../common/Paper/Paper'
import styled from '@emotion/styled'
import theme from '../../theme/theme'
import { IconButton } from '@mui/material'
import { Delete, PinDrop } from '@mui/icons-material'

interface SavedPharmacyCardInterface {
  pharmacyName?: string
  pharmacyAddress?: string
  range?: string
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const ContentWrapper = styled.div`
  h3 {
    font-size: 16px;
    color: ${theme.colors.primary};
    font-weight: 600;
    padding: 10px 0px;
  }
  p {
    color: ${theme.colors.textDark};
    font-size: 14px;
    display: flex;
    align-items: center;
    grid-gap: 0px 10px;
  }
`
const DeleteButtonWrapper = styled.div``

const SavedPharmacyCard: React.FC<SavedPharmacyCardInterface> = ({
  pharmacyName = 'Mail Order Pharmacy',
  pharmacyAddress,
  range,
}) => {
  return (
    <>
      <Paper padding='20px' borderRadius='4px' margin='5px 0px'>
        <Wrapper>
          <ContentWrapper>
            <h3>{pharmacyName}</h3>
            <p>{pharmacyAddress}</p>
            <p>
              {range && <PinDrop style={{ fontSize: '16px' }} />} {range}
            </p>
          </ContentWrapper>
          <DeleteButtonWrapper>
            <IconButton>
              <Delete />
            </IconButton>
          </DeleteButtonWrapper>
        </Wrapper>
      </Paper>
    </>
  )
}

export default SavedPharmacyCard
