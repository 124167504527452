import styled from '@emotion/styled'
import React from 'react'
import InputField from '../common/Input/InputField'
import theme from '../../theme/theme'

const InputWrapper = styled.div``

const Row = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0px 10px;

  .addAnotherReferral {
    padding: 10px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    font-size: 14px;
    cursor: pointer;
    color: ${theme.colors.primary};
    &:hover {
      text-decoration: underline;
      color: ${theme.colors.secondary};
    }
    &:active {
      color: ${theme.colors.primary};
    }
  }

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
  }
`

const ReferralInput: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  return (
    <InputWrapper>
      <Row>
        <InputField
          type='text'
          placeholder='First Name'
          margin='0px 0px'
          fullWidth={isMobile && true}
        />
        <InputField
          type='text'
          placeholder='Last Name'
          margin='0px 0px'
          fullWidth={isMobile && true}
        />
      </Row>
      <Row>
        <InputField
          type='text'
          placeholder='Phone Number'
          margin='0px 0px'
          fullWidth={isMobile && true}
        />
        <InputField
          type='text'
          placeholder='Relationship'
          margin='0px 0px'
          fullWidth={isMobile && true}
        />
      </Row>
    </InputWrapper>
  )
}

export default ReferralInput
