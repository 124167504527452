const RouteConstants = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:id',
  MY_PLANS: '/my-plans',
  HELP: '/help',
  MY_CASES: '/help/my-cases',
  REFERRAL: '/referral',
  MY_REFERRALS: '/referral/my-referrals',
  RESOURCE_CENTER: '/resource-center',
  NOTIFICATIONS: '/notifications',
  DEV_DOCUMENTATION: '/dev-documentation',
  ACCESS_DENIED: '/access-denied',
  PROFILE: '/profile',
  PROFILE_ME: '/profile/me',
  PROFILE_ME_CONTACT: '/profile/me/contact',
  PROFILE_ME_HEALTH: '/profile/me/health',
  PROFILE_ME_PRESCRIPTION: '/profile/me/prescription',
  PROFILE_ME_DOCTOR: '/profile/me/doctor',
  PROFILE_ME_PHARMACY: '/profile/me/pharmacy',
  PROFILE_ME_PREFERENCES: '/profile/me/preferences',
  PROFILE_SPOUSE: '/profile/spouse',
  PROFILE_SPOUSE_HEALTH: '/profile/spouse/health',
  PROFILE_SPOUSE_PRESCRIPTION: '/profile/spouse/prescription',
  PROFILE_SPOUSE_DOCTOR: '/profile/spouse/doctor',
  PROFILE_SPOUSE_PHARMACY: '/profile/spouse/pharmacy',
}

export default RouteConstants
