import styled from '@emotion/styled'
import React from 'react'
import theme from '../../../theme/theme'
import { NavLink, useNavigate } from 'react-router-dom'
import RouteConstants from '../../../constants/RouteConstants'
import Button from '../Button/Button'
import { ArrowBack } from '@mui/icons-material'

interface TopTabGroupInterface {
  showBackButton?: boolean
  navFor?: 'default' | 'profile'
}

const navigationTabs = [
  {
    id: 1,
    title: 'My Plans',
    route: RouteConstants.MY_PLANS,
  },
  {
    id: 2,
    title: 'Help',
    route: RouteConstants.HELP,
  },
  {
    id: 3,
    title: 'Referrals',
    route: RouteConstants.REFERRAL,
  },
  {
    id: 4,
    title: 'Resource Center',
    route: RouteConstants.RESOURCE_CENTER,
  },
]

const profileTabs = [
  {
    id: 1,
    title: 'Me',
    route: RouteConstants.PROFILE_ME,
  },
  {
    id: 2,
    title: 'Spouse/Partner',
    route: RouteConstants.PROFILE_SPOUSE,
  },
]

const MainContainer = styled.div`
  margin-top: 90px;
  padding: 40px 80px 0px 80px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 5px 25px -30px rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid ${theme.colors.borderColor};
  display: flex;
  grid-gap: 50px;

  @media screen and (max-width: 800px) {
    padding: 10px 20px 0px 20px;
    margin-top: 62px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 52px;
  }
`

const Wrapper = styled.div`
  display: flex;
  padding-right: 20px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 0px;
  }
`

const TabButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: auto;
  .active {
    margin-top: 0px;
    padding-top: 30px;
    transition: padding-top 0.2s;
    border-bottom: 4px solid ${theme.colors.primary};
    font-weight: 900;
    background-color: ${theme.colors.primaryBack2};
    @media screen and (max-width: 500px) {
      margin-top: 20px;
      padding-top: 10px;
    }
  }
`

const TabButton = styled(NavLink)`
  padding: 20px 20px;
  font-size: 14px;
  color: ${theme.colors.primary};
  font-weight: 600;
  width: 165px;
  text-align: center;
  text-decoration: none;
  border: 1px solid ${theme.colors.primary};
  cursor: pointer;
  &:hover {
    margin-top: 0px;
    padding-top: 30px;
    transition: padding-top 0.2s;
    border-bottom: 4px solid ${theme.colors.primary};
    font-weight: 900;
    background-color: ${theme.colors.primaryBack2};
  }
  &:active {
    background-color: ${theme.colors.primaryBack};
  }

  @media screen and (max-width: 500px) {
    padding: 10px 20px;
    &:hover {
      margin-top: 20px;
      padding-top: 10px;
    }
  }
`

const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 500px) {
    justify-content: flex-end;
  }
`

const TopTabGroup: React.FC<TopTabGroupInterface> = ({
  showBackButton = false,
  navFor = 'default',
}) => {
  const tabs = navFor === 'profile' ? profileTabs : navigationTabs
  const navigate = useNavigate()
  return (
    <MainContainer>
      {showBackButton ? (
        <BackButtonWrapper>
          <Button
            variant='text'
            fullWidth
            height='40px'
            fontSize='14px'
            underlineText
            startIcon={<ArrowBack />}
            onClick={() => {
              navigate(RouteConstants.MY_PLANS)
            }}
          >
            Back
          </Button>
        </BackButtonWrapper>
      ) : (
        ''
      )}
      <Wrapper>
        {tabs.map((tab) => (
          <TabButtonWrapper key={tab.id}>
            <TabButton
              to={tab.route}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              {tab.title}
            </TabButton>
          </TabButtonWrapper>
        ))}
      </Wrapper>
    </MainContainer>
  )
}

export default TopTabGroup
