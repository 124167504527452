import styled from '@emotion/styled'
import React from 'react'
import theme from '../../theme/theme'

const Wrapper = styled.section<{ divider: boolean }>`
  flex: 1;
  max-width: 800px;
  min-width: 300px;
  padding-bottom: 20px;
  border-bottom: ${({ divider }) =>
    divider ? `1px solid ${theme.colors.borderColor}` : ''};
`
const Label = styled.h4`
  font-size: 14px;
  color: ${theme.colors.textDark};
  font-weight: 600;
  padding-bottom: 10px;
  padding-top: 20px;
`

const Section: React.FC<{
  children: any
  label: string
  divider?: boolean
}> = ({ children, label, divider = false }) => {
  return (
    <Wrapper divider={divider}>
      <Label>{label}</Label>
      {children}
    </Wrapper>
  )
}

export default Section
