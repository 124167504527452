import ApiManager from '../../api/ApiManager'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import USStates from '../../constants/USStates'
import UrlConstants from '../../constants/UrlConstants'
import { isEmpty } from 'lodash'
import moment from 'moment'

const validateEmail = (email: string) => {
  if (isEmpty(email)) return false
  else if (email.length > 255) return false
  // TODO: validate email better way(this one is causing few minor issues)
  else if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  )
    return true
  else return false
}

const validateCurrentPassword = (password: string) => {
  if (isEmpty(password)) return false
  else if (password.length > 255) return false
  // TODO: validate password
  // else if (
  //   /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])*(?=.*[^a-zA-Z0-9])*(?!.*\s).{8,}$/.test(
  //     password
  //   )
  // )
  //   return true
  // else return false
  else return true
}

const validatePasswordToBeSet = (password: string) => {
  if (isEmpty(password)) return false
  else if (password.length > 255) return false
  else if (
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
      password
    )
  )
    return true
  else return false
}

const validatePhoneNumber = (phoneNumber: string) => {
  if (isEmpty(phoneNumber)) return false
  else if (phoneNumber.length > 255) return false
  const phoneNumberPattern =
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  if (phoneNumberPattern.test(phoneNumber)) return true
  return false
}

const validateUsername = (name: string) => {
  if (isEmpty(name)) return false
  if (name.length !== 7) return false
  if (/^[a-zA-Z0-9-\s]+$/.test(name)) return true
  return false
}

const validateZipcode = (zipcode: string) => {
  if (isEmpty(zipcode)) return false
  if (/(^\d{5}$)/.test(zipcode)) return true
  return false
}

const validateState = (state: string) => {
  const stateInUpper = state.toUpperCase()
  if (isEmpty(stateInUpper)) return false

  if (/^([A-Z]{2})$/.test(stateInUpper)) {
    const usStates = USStates.STATES.map((estado) => estado.abbreviation)
    if (usStates.includes(stateInUpper)) return true
    else return false
  }
  return false
}

const validateCity = (city: string) => {
  if (isEmpty(city)) return false
  else if (city.length > 255) return false
  if (
    /[a-zA-z]{3,}/.test(city) &&
    /^[a-zA-Z\u0080-\u024F]+(?:([\ \-\']|(\.\ ))[a-zA-Z\u0080-\u024F]+)*$/.test(
      city
    )
  )
    return true
  else return false
}

const validateStreet = (street: string) => {
  if (isEmpty(street)) return false
  else if (street.length > 255) return false
  if (/[a-zA-z]{3,}/.test(street)) return true
  else return false
}

const validateFirstName = (firstName: string) => {
  if (isEmpty(firstName)) return false
  else if (firstName.length > 255) return false
  else if (/^(?!'$)(?!''+$)[a-zA-Z0-9' ]+(?<!^')$/.test(firstName)) return true
  else return false
}

const validateLastName = (lastName: string) => {
  if (isEmpty(lastName)) return false
  else if (lastName.length > 255) return false
  else if (/^(?!'$)(?!''+$)[a-zA-Z0-9' ]+(?<!^')$/.test(lastName)) return true
  else return false
}

const validateDOB = (dob: string) => {
  if (isEmpty(dob)) return false
  let currentDate = moment().format('YYYY-MM-DD')
  if (moment(dob).isAfter(moment(currentDate))) return false
  else return true
}

const validateEffectiveDate = (effectiveDate: string) => {
  if (isEmpty(effectiveDate)) return false
  else return true
}

const validateAnticipatedEndDate = (
  anticipatedEndDate: string,
  effectiveDate: string
) => {
  if (isEmpty(anticipatedEndDate)) return false
  if (moment(anticipatedEndDate).isSameOrBefore(moment(effectiveDate)))
    return false
  else return true
}

const validateDrugQuantity = (quantity: string) => {
  if (isEmpty(quantity)) return false
  else if (quantity.length > 2) return false
  else if (/^[1-9][0-9]{0,2}$/.test(quantity)) return true
  else return false
}

const verifyPhoneNumber = (phone: string) => {
  return new Promise((resolve, reject) => {
    if (validatePhoneNumber(phone)) {
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.VERIFY_PHONE_NUMBER.USECASE,
        HttpMethods.GET_METHOD,
        {
          $phone: '+1' + phone,
        }
      )
        .then((response) => {
          resolve(response)
        })
        .catch((err: Error) => {
          reject(err)
        })
    }
  })
}

const ValidationUtils = {
  validateEmail,
  validateCurrentPassword,
  validatePasswordToBeSet,
  validatePhoneNumber,
  validateUsername,
  validateZipcode,
  validateState,
  validateCity,
  validateStreet,
  validateFirstName,
  validateLastName,
  validateDOB,
  validateEffectiveDate,
  validateAnticipatedEndDate,
  validateDrugQuantity,
  verifyPhoneNumber,
}

export default ValidationUtils
