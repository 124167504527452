import React from 'react'
import Paper from '../common/Paper/Paper'
import styled from '@emotion/styled'
import theme from '../../theme/theme'

const SavedPrescriptionCardWrapper = styled.div`
  color: ${theme.colors.textDark};
  position: relative;
`

const Header = styled.div`
  h3 {
    font-size: 18px;
  }
  p {
    font-size: 12px;
    font-style: italic;
  }
`
const CardSection = styled.div`
  padding-top: 20px;
  h4 {
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }
`
const HandlerButtons = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  span {
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    color: ${theme.colors.primary};
    font-style: italic;
    text-decoration: underline;
    &:hover {
      color: ${theme.colors.secondary};
    }
  }
`

const SavedPrescriptionCard = () => {
  return (
    <Paper borderRadius='4px' padding='20px' margin='5px'>
      <SavedPrescriptionCardWrapper>
        <Header>
          <h3>Simchol</h3>
          <p>Brand name drug</p>
        </Header>
        <CardSection>
          <h4>Dose</h4>
          <p>Simchol TAB 10mg</p>
        </CardSection>
        <CardSection>
          <h4>Frequency</h4>
          <p>1 per day</p>
        </CardSection>
        <HandlerButtons>
          <span>Edit</span>
          <span>Delete</span>
        </HandlerButtons>
      </SavedPrescriptionCardWrapper>
    </Paper>
  )
}

export default SavedPrescriptionCard
