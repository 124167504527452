import { AiOutlineHeart } from 'react-icons/ai'
import { BiMessageDetail } from 'react-icons/bi'
import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import SidebarLayout from '../../components/common/Layout/SidebarLayout'

const ReferralRoutes = [
  {
    id: 1,
    title: 'Refer Someone',
    route: RouteConstants.REFERRAL,
    icon: <AiOutlineHeart style={{ fontSize: '18px' }} />,
  },
  {
    id: 2,
    title: 'My Referrals',
    route: RouteConstants.MY_REFERRALS,
    icon: <BiMessageDetail style={{ fontSize: '18px' }} />,
  },
]

const Referral = () => {
  return <SidebarLayout sidebarRoutes={ReferralRoutes} />
}

export default Referral
