import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import React from 'react'
import theme from '../../../theme/theme'
import styled from '@emotion/styled'

export type checkboxStateType = {
  checked: boolean
  label: string | number
  value?: string | number
  disabled?: boolean
}

interface ChecboxGroupProps {
  formLabel?: string
  checkboxState: checkboxStateType[]
  row?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  width?: string
  readOnly?: boolean
  fontSize?: string
  padding?: string
}

const FormControlLabelStyled = styled(FormControlLabel)``

const CheckboxGroup: React.FC<ChecboxGroupProps> = ({
  formLabel,
  onChange,
  row,
  checkboxState,
  width = '100%',
  readOnly = false,
  fontSize = '14px',
  padding = '0px 10px',
}) => {
  return (
    <FormControl
      sx={{
        width,
        pointerEvents: readOnly ? 'none' : '',
      }}
    >
      <FormLabel sx={{ fontStyle: 'italic', fontSize: '14px' }}>
        {formLabel}
      </FormLabel>
      <FormGroup onChange={onChange} row={row ? row : false}>
        {checkboxState.map((checkbox, index) => (
          <FormControlLabelStyled
            key={index}
            control={
              <Checkbox
                style={readOnly ? { color: theme.colors.borderColor } : {}}
                size='small'
                disableRipple={readOnly}
              />
            }
            label={checkbox.label}
            value={checkbox.value ? checkbox.value : checkbox.label}
            checked={checkbox.checked}
            sx={{
              padding: padding,
              margin: row ? '0px 2.5px' : '2.5px 0px',
              borderRadius: '4px',
              border: checkbox.checked
                ? `1px solid ${theme.colors.primary}`
                : `1px solid ${theme.colors.borderColor}`,
              backgroundColor: checkbox.checked ? theme.colors.lightBack2 : '',
              '&:hover': {
                background: theme.colors.lightBack2,
                transition: 'background 0.4s',
              },
              '& .MuiFormControlLabel-label': {
                fontSize,
                color: theme.colors.textDark,
              },
            }}
            disabled={checkbox.disabled ? true : false}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

export default CheckboxGroup
