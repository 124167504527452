import React from 'react'
import Modal from '../common/Modal/Modal'
import InputField from '../common/Input/InputField'
import Button from '../common/Button/Button'
import styled from '@emotion/styled'

const ChangeZipCodeModal = ({
  open,
  handleClose,
}: {
  open: boolean
  handleClose: () => void
}) => {
  return (
    <Modal open={open} handleClose={handleClose} heading='Change zip code'>
      <InputField
        type='text'
        placeholder='Enter zipcode'
        superScript='Enter Zipcode'
        fullWidth
      />

      <Button fullWidth>Change</Button>
      <Button
        fullWidth
        variant='outlined'
        color='secondary'
        onClick={handleClose}
      >
        Cancel
      </Button>
    </Modal>
  )
}

export default ChangeZipCodeModal
