import React from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../store/store'
import { useLocation } from 'react-router-dom'

export const useWindowWidth: (breakpoint?: number) => boolean = (
  breakpoint = 500
) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < breakpoint)

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < breakpoint)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [breakpoint])

  return isMobile
}

export const useScrollToTop = () => {
  const location = useLocation()
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    // Scroll to top when the component is mounted
    scrollToTop()

    // Scroll to top when the user navigates to a new page
    const handleNavigation = () => {
      scrollToTop()
    }

    window.addEventListener('popstate', handleNavigation)

    return () => {
      window.removeEventListener('popstate', handleNavigation)
    }
  }, [location])
}

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
