import { InputAdornment } from '@mui/material'
import MuiTextField from '@mui/material/TextField'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../theme/theme'

interface InputFieldProps {
  placeholder?: string
  label?: string
  width?: string
  fullWidth?: boolean
  color?: 'primary' | 'secondary' | 'info' | undefined
  error?: boolean
  type?: string
  symbol?: string | JSX.Element
  value?: string | number | null
  helperText?: string
  readOnly?: boolean
  onBlur?: (e: any) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  inputProps?: { min?: string; max?: string; mask?: string }
  margin?: string
  superScript?: string
}

const Container = styled.div<{
  width: string
  fullWidth: boolean
  margin: string
}>`
  width: ${({ fullWidth, width }) => (fullWidth ? '100%' : width)};
  margin: ${({ margin }) => margin};
`

const TextField = styled(MuiTextField)({
  '.MuiOutlinedInput-root': {
    fontSize: '16px',
    borderRadius: '35px',
  },
  '.MuiOutlinedInput-input': {},
  '.MuiInputLabel-root': {
    fontSize: '16px',
  },
  '.MuiInputLabel-shrink': {
    marginInlineStart: '-4px',
  },
})

const Label = styled.p`
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-left: 14px;
  color: ${theme.colors.textDark};
`
const SymbolWrapper = styled.span`
  color: ${theme.colors.primary};
  margin: 0px 5px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
`

const InputField: React.FC<InputFieldProps> = ({
  label,
  fullWidth = false,
  width = '265px',
  color = 'primary',
  error = false,
  type = 'text',
  value,
  symbol = '',
  helperText = '',
  readOnly = false,
  onBlur,
  onChange,
  margin = '10px 0px',
  placeholder,
  disabled,
  inputProps,
  superScript,
}) => {
  return (
    <Container width={width} fullWidth={fullWidth} margin={margin}>
      {superScript && <Label>{superScript}</Label>}
      <TextField
        onChange={onChange}
        onBlur={(e) => {
          onBlur && onBlur(e)
        }}
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            e.target.blur()
          }
        }}
        variant='outlined'
        color={color}
        label={label}
        margin='dense'
        size='small'
        value={value}
        error={error}
        helperText={helperText}
        fullWidth={true}
        type={type}
        placeholder={placeholder}
        disabled={disabled ? disabled : false}
        inputProps={inputProps}
        InputProps={{
          startAdornment:
            symbol !== '' ? (
              <InputAdornment position='start'>
                <SymbolWrapper>{symbol}</SymbolWrapper>
              </InputAdornment>
            ) : undefined,
          readOnly: readOnly,
        }}
      />
    </Container>
  )
}

export default InputField
