import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../../../theme/theme'

interface DatePickerProps {
  disableFuture?: boolean
  disablePast?: boolean
  disabled?: boolean
  label?: string
  value?: any
  maxDate?: any
  minDate?: any
  onAccept?: (date: any) => void
  onChange?: (date: any) => void
  onClose?: () => void
  onOpen?: () => void
  readonly?: boolean
  error?: boolean
  helperText?: string
  width?: string
  fullWidth?: boolean
  margin?: string
  superScript?: string
}

const Container = styled.div<{
  width: string
  fullWidth: boolean
  margin: string
}>`
  width: ${({ fullWidth, width }) => (fullWidth ? '100%' : width)};
  margin: ${({ margin }) => margin};
`

const Label = styled.p`
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-left: 14px;
  color: ${theme.colors.textDark};
`

const CustomDatePicker = styled(MuiDatePicker)({
  '.MuiOutlinedInput-root': {
    borderRadius: '36px',
    fontSize: '16px',
    margin: '0px 0px',
  },
  '.MuiOutlinedInput-input': {
    padding: '8.5px 14px',
  },
  '.MuiInputLabel-root': {
    fontSize: '16px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.colors.borderColor,
  },
  '.MuiFormHelperText-root': {
    marginTop: '-4px',
  },
})

const DatePicker: React.FC<DatePickerProps> = ({
  disableFuture,
  disablePast = false,
  value,
  maxDate,
  minDate,
  onAccept,
  onChange,
  onClose,
  onOpen,
  readonly = true,
  error,
  disabled,
  helperText,
  width = '265px',
  fullWidth = false,
  margin = '10px 0px',
  superScript,
}) => {
  return (
    <Container width={width} fullWidth={fullWidth} margin={margin}>
      {superScript && <Label>{superScript}</Label>}
      <CustomDatePicker
        value={value}
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={minDate}
        maxDate={maxDate}
        onAccept={onAccept}
        disabled={disabled}
        formatDensity='spacious'
        slotProps={{
          textField: {
            fullWidth: true,
            margin: 'dense',
            readOnly: readonly,
            error: error,
            helperText: error ? helperText : '',
          } as any,
        }}
        onOpen={() => {
          onOpen && onOpen()
          if (window.scrollY < 200) window.scrollTo({ top: 200 })
          // Get the current page scroll position
          window.onscroll = function () {
            window.scrollTo(document.documentElement.scrollLeft, 200)
          }
        }}
        onClose={() => {
          onClose && onClose()
          window.onscroll = function () {}
        }}
        onChange={onChange}
      />
    </Container>
  )
}
export default DatePicker
