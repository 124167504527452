import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import MuiRadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import React from 'react'
import theme from '../../../theme/theme'

const radioStyles = {
  backgroundColor: `${theme.colors.lightBack}`,
  border: `1px solid ${theme.colors.borderColor}`,
  borderRadius: '4px',
  color: theme.colors.textDark,
  margin: '0px',
  minWidth: '150px',
}

const errorRadioStyles = {
  backgroundColor: `${theme.colors.secondaryBack}`,
  border: `1px solid ${theme.colors.errorBorder}`,
  borderRadius: '4px',
  margin: '0px',
  minWidth: '150px',
}

const unstyledRadioStyles = {}

type RadioButtonType = {
  value: string
  label: any
  disabled?: boolean
}

interface RadioGroupProps {
  groupLabel?: string
  radioButtons: Array<RadioButtonType>
  row?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  width?: string
  type?: 'default' | 'unstyled'
  value: string
  error?: boolean
  fontSize?: string
  padding?: string
  groupMargin?: string
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  groupLabel,
  radioButtons,
  row = false,
  onChange,
  width = '100%',
  type = 'default',
  value,
  error = false,
  fontSize = '16px',
  padding = '0px 10px',
  groupMargin = '0px 0px',
}) => {
  const [radioValue, setRadioValue] = React.useState(value)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value)
    onChange && onChange(event)
  }

  React.useEffect(() => {
    setRadioValue(value)
  }, [value])

  return (
    <FormControl sx={{ width: `${width}`, margin: groupMargin }}>
      <FormLabel
        sx={{
          fontSize: '14px',
          color: theme.colors.textDark,
          fontWeight: 600,
          fontFamily: 'inherit',
          paddingBottom: '10px',
        }}
      >
        {groupLabel}
      </FormLabel>
      <MuiRadioGroup
        onChange={handleChange}
        row={row}
        value={radioValue}
        sx={{ gridGap: '5px' }}
      >
        {radioButtons &&
          radioButtons.map((radio) => (
            <FormControlLabel
              key={radio.value}
              value={radio.value}
              disabled={radio.disabled}
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 14,
                    },
                  }}
                />
              }
              label={radio.label}
              sx={
                error === true
                  ? {
                      ...errorRadioStyles,
                      padding: padding,
                      '& .MuiFormControlLabel-label': {
                        fontSize,
                      },
                      '& .MuiFormControlLabel-root': {
                        paddingBottom: '3px !important',
                      },
                    }
                  : type === 'default'
                  ? {
                      '&:hover': {
                        background: theme.colors.lightBack2,
                        transition: 'background 0.4s',
                      },

                      ...radioStyles,
                      padding: padding,
                      '& .MuiFormControlLabel-label': {
                        fontSize,
                      },
                      '& .MuiFormControlLabel-root': {
                        paddingBottom: '3px !important',
                      },
                      '&:has(.Mui-checked)': {
                        border: `1px solid ${theme.colors.primary}`,
                        background: theme.colors.lightBack2,
                      },
                    }
                  : {
                      ...unstyledRadioStyles,
                      '& .MuiFormControlLabel-label': {
                        fontSize,
                      },
                    }
              }
            />
          ))}
      </MuiRadioGroup>
    </FormControl>
  )
}

export default RadioGroup
