const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const monthsMappedToObject = months.map((month, index) => {
  return {
    label: month,
    value: index,
  }
})

const years: number[] = []
const currentYear = new Date().getFullYear() + 1
const startYear = 1950

for (let i = currentYear; i > startYear - 1; i--) {
  years.push(i)
}

const yearsMappedToObject = years.map((year) => {
  return {
    label: year,
    value: year,
  }
})

export { months, monthsMappedToObject, years, yearsMappedToObject }
