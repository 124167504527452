import React from 'react'
import Paper from '../Paper/Paper'
import styled from '@emotion/styled'
import eipFlag from '../../../assets/eipFlag.png'
import Navlinks from './Navlinks'

const MainWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`

const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 120px;
    @media screen and (max-width: 800px) {
      width: 100px;
    }
    @media screen and (max-width: 500px) {
      width: 70px;
    }
  }
`

const Navbar = () => {
  return (
    <MainWrapper>
      <Paper
        borderColor='rgba(0,0,0,0)'
        borderRadius='0px'
        type='card'
        padding={`20px 80px; @media screen and (max-width: 800px) {padding: 10px 20px;}`}
      >
        <NavWrapper>
          <ImageWrapper>
            <img src={eipFlag} alt='brandLogo' />
          </ImageWrapper>
          <Navlinks />
        </NavWrapper>
      </Paper>
    </MainWrapper>
  )
}

export default Navbar
