import styled from '@emotion/styled'
import { TextareaAutosize } from '@mui/material'
import React from 'react'
import theme from '../../../theme/theme'

interface TextAreaProps {
  error?: boolean
  width?: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement> | undefined) => void
  placeholder?: string
}

const TextAreaComponent = styled(TextareaAutosize)<{
  error: number
  width: string
}>`
  width: ${({ width }) => (width ? width : '100%')};
  min-height: 80px;
  resize: vertical;
  padding: 20px;
  border: 1px solid
    ${({ error }) =>
      error ? theme.colors.errorBorder : theme.colors.borderColor};
  background-color: ${({ error }) =>
    error ? theme.colors.secondaryBack : theme.colors.lightBack};
  border-radius: 4px;
  color: ${theme.colors.textDark};
  font-size: 14px;
`

const TextArea: React.FC<TextAreaProps> = ({
  error = false,
  width = '100%',
  onChange,
  placeholder,
}) => {
  return (
    <TextAreaComponent
      error={error ? 1 : 0}
      width={width}
      onChange={onChange}
      placeholder={placeholder}
    />
  )
}

export default TextArea
