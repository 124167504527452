import React from 'react'
import Paper from '../common/Paper/Paper'
import styled from '@emotion/styled'
import theme from '../../theme/theme'
import { BsFillCircleFill as FilledCircle } from 'react-icons/bs'

const Header = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 50px;
  h3 {
    font-size: 16px;
    font-weight: 700;
    color: ${theme.colors.textDark};

    @media screen and (max-width: 800px) {
      font-size: 14px;
    }
  }
  p {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    font-size: 12px;
  }
`
const ContentWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  p {
    color: ${theme.colors.textDark};
    font-size: 16px;
    @media screen and (max-width: 800px) {
      font-size: 14px;
    }
  }

  .time {
    font-size: 14px;
    color: ${theme.colors.textGrey};
    @media screen and (max-width: 800px) {
      font-size: 12px;
    }
  }
`

const MyReferralCard = () => {
  return (
    <Paper
      type='card'
      padding='20px 50px; @media screen and (max-width: 1000px) {
      padding: 20px 20px;
    }'
      margin='15px 0px'
    >
      <Header>
        <h3>Referral for Scott, Sydney</h3>
        <p>
          <FilledCircle color={theme.colors.warning} /> Ongoing
        </p>
      </Header>
      <ContentWrapper>
        <p>Scott, Sydney (+914 883 7296) - Partner</p>
        <p className='time'>29 May 2022 12:34 PM</p>
      </ContentWrapper>
    </Paper>
  )
}

export default MyReferralCard
