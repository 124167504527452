import React from 'react'
import ReactDOM from 'react-dom/client'
import './theme/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { IconButton, ThemeProvider } from '@mui/material'
import muiTheme from './theme/muiTheme'
import { BrowserRouter } from 'react-router-dom'
import { Provider as StoreProvider } from 'react-redux'
import { store } from './store/store'
import {
  MaterialDesignContent,
  SnackbarProvider,
  closeSnackbar,
} from 'notistack'
import { CloseOutlined } from '@mui/icons-material'
import theme from './theme/theme'
import styled from '@emotion/styled'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.colors.success,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.colors.error,
  },
}))

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={muiTheme}>
          <StoreProvider store={store}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={2000}
              preventDuplicate={true}
              action={
                <IconButton
                  onClick={() => {
                    closeSnackbar()
                  }}
                >
                  <CloseOutlined sx={{ color: theme.colors.textLight }} />
                </IconButton>
              }
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
              }}
              style={{
                borderRadius: '36px',
                flexWrap: 'nowrap',
              }}
            >
              <App />
            </SnackbarProvider>
          </StoreProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
