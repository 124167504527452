import {
  CardWrapper,
  Footer,
  ForgotPasswordWrapper,
  Form,
  LogoWrapper,
  MainContainer,
  SSOWrapper,
  SSOWrapperMain,
  SignupWrapper,
  Wrapper,
} from './LoginStyled'
import { Link, useNavigate } from 'react-router-dom'

import { AiOutlineRight } from 'react-icons/ai'
import ApiManager from '../../api/ApiManager'
import Button from '../../components/common/Button/Button'
import { FcGoogle } from 'react-icons/fc'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import { ImFacebook } from 'react-icons/im'
import InputField from '../../components/common/Input/InputField'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import Paper from '../../components/common/Paper/Paper'
import PasswordField from '../../components/common/Input/PasswordField'
import React from 'react'
import RouteConstants from '../../constants/RouteConstants'
import { StatusCode } from '../../enums/statusCode.enum'
import StringConstants from '../../constants/StringConstants'
import UrlConstants from '../../constants/UrlConstants'
import ValidationUtils from '../../utils/validation/ValidationUtils'
import cookie from 'react-cookies'
import eipFlag from '../../assets/eipFlag.png'
import { isEmpty } from 'lodash'
import theme from '../../theme/theme'
import { useSnackbar } from 'notistack'

const Login = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [loginState, setLoginState] = React.useState({
    email: '',
    password: '',
    emailError: false,
    passwordError: false,
    loading: false,
    validated: false,
  })

  const isValidEmail = (email: string) => {
    return ValidationUtils.validateEmail(email)
  }

  const isValidPassword = (password: string) => {
    return ValidationUtils.validateCurrentPassword(password)
  }

  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let email = isEmpty(e.target.value) ? '' : e.target.value
    setLoginState({
      ...loginState,
      email,
      emailError: !isValidEmail(email),
    })
  }

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      password: e.target.value,
      passwordError: !isValidPassword(e.target.value),
    })
  }

  const loginToCustomerPortal = async (email: string, password: string) => {
    try {
      const response = await ApiManager.makeApiCall(
        UrlConstants.SIGNIN.USECASE,
        HttpMethods.POST_METHOD,
        {
          email,
          password,
        }
      ).catch((error) => {
        throw error
      })

      if (response && response.status !== StatusCode.OK) {
        enqueueSnackbar(response.data.message, {
          variant: 'error',
        })
      } else if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.token
      ) {
        cookie.save(StringConstants.COOKIE_TOKEN, response.data.data.token, {
          path: RouteConstants.HOME,
        })
        navigate(0)
      } else {
        throw response
      }
    } catch (error) {
      enqueueSnackbar(StringConstants.INTERNAL_SERVER_ERROR, {
        variant: 'error',
      })
    } finally {
      setLoginState({ ...loginState, loading: false })
    }
  }

  const handleLogin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    let emailError = !isValidEmail(loginState.email),
      passwordError = !isValidPassword(loginState.password)

    if (emailError || passwordError) {
      setLoginState({
        ...loginState,
        emailError,
        passwordError,
      })
    } else {
      setLoginState({
        ...loginState,
        emailError: false,
        passwordError: false,
        loading: true,
      })
      loginToCustomerPortal(loginState.email, loginState.password)
    }
  }

  return (
    <MainContainer>
      <Wrapper>
        <LogoWrapper>
          <img src={eipFlag} alt='elite insurance partners' />
        </LogoWrapper>
        <Paper
          type='card'
          margin='20px 0px'
          padding='20px'
          boxShadow='0px 0px 12px -2px rgba(0, 0, 0, 0.05)'
        >
          <CardWrapper>
            <h2>Welcome back to EIP CustomerHQ</h2>
            <Form>
              <InputField
                placeholder='Your email'
                superScript='Enter your email'
                type='email'
                onChange={handleEmailInput}
                value={loginState.email || ''}
                symbol={<MailOutlineIcon />}
                fullWidth
                error={loginState.emailError}
                helperText={
                  loginState.emailError
                    ? StringConstants.UI_ERROR_MSG_FOR_INVALID_EMAIL
                    : ''
                }
              />
              <PasswordField
                superScript='Enter your password'
                fullWidth
                placeholder='Your Password'
                error={loginState.passwordError}
                helperText={
                  loginState.passwordError
                    ? StringConstants.UI_ERROR_MSG_FOR_INVALID_PASSWORD
                    : ''
                }
                onChange={handlePasswordInput}
              />
              <ForgotPasswordWrapper to={RouteConstants.FORGOT_PASSWORD}>
                <span>Forgot Password?</span>
              </ForgotPasswordWrapper>
              <Button
                endIcon={<AiOutlineRight />}
                fullWidth
                onClick={handleLogin}
                loading={loginState.loading}
                disabled={loginState.loading}
              >
                Sign In
              </Button>
              <SignupWrapper>
                <p>Already a customer but don’t have an account?</p>
                <Link className='signupLabel' to={RouteConstants.SIGNUP}>
                  Create an Account
                </Link>
              </SignupWrapper>
              <SSOWrapperMain>
                <p className='SSOHeading'>
                  <span>or Sign In with</span>
                </p>
                <SSOWrapper>
                  <div className='sso'>
                    <FcGoogle />
                  </div>
                  <div className='sso'>
                    <ImFacebook style={{ color: theme.colors.primary }} />
                  </div>
                </SSOWrapper>
              </SSOWrapperMain>
            </Form>
          </CardWrapper>
        </Paper>
        <Footer>
          <span>Privacy Policy</span>
          <span>Terms & Conditions</span>
        </Footer>
      </Wrapper>
    </MainContainer>
  )
}

export default Login
