import styled from '@emotion/styled'
import React from 'react'
import Button from '../../components/common/Button/Button'
import { useNavigate } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import theme from '../../theme/theme'
import Notification from '../../components/common/Navbar/Notification'

const Header = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: ${theme.colors.primary};
`
const NotificationsWrapper = styled.div``

const ButtonsWrapper = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .clearNotification {
    text-decoration: underline;
    color: ${theme.colors.primary};
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      color: ${theme.colors.secondary};
    }
  }
`

const Notifications = () => {
  const navigate = useNavigate()
  return (
    <>
      <ButtonsWrapper>
        <Button
          startIcon={<AiOutlineArrowLeft />}
          variant='text'
          borderRadius='4px'
          width='90px'
          underlineText
          onClick={() => {
            navigate(-1)
          }}
        >
          Back
        </Button>
        <span className='clearNotification'>Clear Notification</span>
      </ButtonsWrapper>
      <Header>Notifications</Header>
      <NotificationsWrapper>
        <Notification />
        <Notification />
        <Notification />
        <Notification />
      </NotificationsWrapper>
    </>
  )
}

export default Notifications
