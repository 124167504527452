import React from 'react'
import Paper from '../common/Paper/Paper'
import styled from '@emotion/styled'
import theme from '../../theme/theme'
import DatePicker from '../common/Input/DatePicker'
import InputField from '../common/Input/InputField'
import RadioGroup from '../common/RadioGroup/RadioGroup'
import Button from '../common/Button/Button'
import { useNavigate } from 'react-router-dom'
import RouteConstants from '../../constants/RouteConstants'
import oldCouple from '../../assets/oldCouple.svg'
import { enqueueSnackbar } from 'notistack'
import { TbTicket } from 'react-icons/tb'

const MainContainer = styled.div`
  max-width: 400px;
`

const Header = styled.h2`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.colors.primary};
`

const Section = styled.div`
  padding-top: 20px;
  p {
    max-width: 350px;
    color: ${theme.colors.textDark};
    font-size: 14px;

    span {
      font-weight: 700;
    }
  }
`

const SectionLabel = styled.h4`
  font-size: 14px;
  font-weight: 700;
  color: ${theme.colors.primary};
  padding-bottom: 0px;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  img {
    width: 180px;
  }
`

const Ticket = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 5px;
`

const SpouseOnboardingCard = () => {
  const [cardStep, setCardStep] = React.useState(0)
  const navigate = useNavigate()

  return (
    <Paper padding='20px' borderRadius='4px'>
      <MainContainer>
        {cardStep !== 2 && (
          <Header>
            Get household discount of up to{' '}
            <span style={{ color: theme.colors.secondary }}>15%</span> on adding
            your spouse/partner
          </Header>
        )}

        {cardStep === 0 && (
          <>
            <Section>
              <SectionLabel>Your Spouse/Partner's Date of Birth</SectionLabel>
              <DatePicker fullWidth />
            </Section>
            <Section>
              <SectionLabel>Gender</SectionLabel>
              <RadioGroup
                groupMargin='10px 0px'
                radioButtons={[
                  {
                    value: 'male',
                    label: 'Male',
                  },
                  {
                    value: 'female',
                    label: 'Female',
                  },
                ]}
                onChange={() => {}}
                value=''
              />
            </Section>
            <Section>
              <Button
                fullWidth
                fontSize='14px'
                onClick={() => {
                  setCardStep((cardStep) => cardStep + 1)
                }}
              >
                Tell us a bit about Spouse/Partner
              </Button>
            </Section>
          </>
        )}

        {cardStep === 1 && (
          <>
            <Section>
              <SectionLabel>Your Spouse/Partner’s first name</SectionLabel>
              <InputField fullWidth margin='0px 0px' placeholder='First name' />
            </Section>
            <Section>
              <SectionLabel>Your Spouse/Partner’s last name name</SectionLabel>
              <InputField fullWidth margin='0px 0px' placeholder='Last name' />
            </Section>
            <Section>
              <Button
                fullWidth
                margin='20px 0px 0px 0px'
                onClick={() => {
                  enqueueSnackbar('Request successfully sent', {
                    variant: 'success',
                  })
                  setCardStep((cardStep) => cardStep + 1)
                }}
              >
                Finish
              </Button>
              <Button
                fullWidth
                variant='outlined'
                color='secondary'
                onClick={() => {
                  setCardStep((cardStep) => cardStep - 1)
                }}
              >
                Back
              </Button>
            </Section>
          </>
        )}

        {cardStep === 2 && (
          <>
            <Section>
              <ImageWrapper>
                <img src={oldCouple} alt='couple' />
              </ImageWrapper>
            </Section>
            <Section>
              <p>
                {' '}
                We have received your request for adding your{' '}
                <span>spouse/partner's information</span>
              </p>
            </Section>
            <Section>
              <p>
                A new case <span>'Request for spouse/partner addition'</span>{' '}
                has been created. You can track the progress on{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    color: theme.colors.primary,
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate(RouteConstants.MY_CASES)}
                >
                  My Cases
                </span>{' '}
                page.
              </p>
            </Section>
            <Section>
              <Paper
                margin='20px 0px'
                padding='10px 20px'
                borderRadius='4px'
                backgroundColor={theme.colors.lightBack}
              >
                <Ticket>
                  <TbTicket
                    style={{ fontSize: '18px', color: theme.colors.secondary }}
                  />{' '}
                  <p>
                    Ticket <span>#3456</span>
                  </p>
                </Ticket>
              </Paper>
            </Section>
          </>
        )}
      </MainContainer>
    </Paper>
  )
}

export default SpouseOnboardingCard
