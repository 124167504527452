import React from 'react'
import Modal from '../common/Modal/Modal'
import { AiFillCheckCircle } from 'react-icons/ai'
import theme from '../../theme/theme'
import styled from '@emotion/styled'

interface ModalInterface {
  open: boolean
  handleClose: () => void
  heading?: string
}

const Container = styled.div`
  display: flex;
  grid-gap: 10px;
`

const TextWrapper = styled.div`
  color: ${theme.colors.textDark};
  p {
    padding-bottom: 20px;
  }
`

const ProfileUpdationSuccessModal: React.FC<ModalInterface> = ({
  open,
  handleClose,
  heading = 'Thank You',
}) => {
  return (
    <Modal open={open} handleClose={handleClose} heading={heading}>
      <Container>
        <AiFillCheckCircle
          style={{ color: theme.colors.success, fontSize: '24px' }}
        />
        <TextWrapper>
          <p>Hi Michael, your profile has been successfully updated.</p>
          <p>
            These changes have been saved to your Account for when you decide to
            update your policy with us!
          </p>
        </TextWrapper>
      </Container>
    </Modal>
  )
}

export default ProfileUpdationSuccessModal
