import styled from '@emotion/styled'
import theme from '../../theme/theme'
import { Link } from 'react-router-dom'

const MainContainer = styled.div`
  background: linear-gradient(429.24deg, #dfecff 36.61%, #ffffff 99.85%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 120px;
  }
`

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  span {
    padding: 0px 20px;
    font-size: 12px;
    font-weight: 600;
    color: ${theme.colors.textDark};
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: ${theme.colors.secondary};
    }
  }
`
const CardWrapper = styled.div`
  width: 350px;
  text-align: center;
  h2 {
    padding-top: 20px;
    font-size: 24px;
    color: ${theme.colors.primary};
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const ForgotPasswordWrapper = styled(Link)`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  span {
    font-size: 12px;
    font-weight: 600;
    color: ${theme.colors.primary};
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
    &:hover {
      color: ${theme.colors.secondary};
    }
  }
`
const SignupWrapper = styled.div`
  color: ${theme.colors.textDark};
  font-size: 12px;
  padding-top: 10px;

  .signupLabel {
    color: ${theme.colors.primary};
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
    &:hover {
      color: ${theme.colors.secondary};
    }
  }
`
const SSOWrapperMain = styled.div`
  padding-top: 20px;
  .SSOHeading {
    border-bottom: 1px solid ${theme.colors.textDark};
    line-height: 0.1em;
    margin: 0px 100px;
    @media screen and (max-width: 500px) {
      margin: 0px 50px;
    }
    span {
      background: ${theme.colors.white};
      color: ${theme.colors.textDark};
      font-size: 12px;
      padding: 0px 5px;
    }
  }
`

const SSOWrapper = styled.div`
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  grid-gap: 50px;

  .sso {
    width: 50px;
    height: 50px;
    border: 1px solid ${theme.colors.borderColor};
    border-radius: 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      background-color: ${theme.colors.primaryBack};
    }
    &:active {
      box-shadow: none;
    }
  }
`
const Form = styled.div``

export {
  MainContainer,
  Wrapper,
  LogoWrapper,
  Footer,
  CardWrapper,
  ForgotPasswordWrapper,
  SignupWrapper,
  SSOWrapperMain,
  SSOWrapper,
  Form,
}
