import styled from '@emotion/styled'
import React from 'react'
import theme from '../../theme/theme'
import {
  ContactSupportOutlined,
  HelpOutlineOutlined,
  KeyboardArrowRight,
} from '@mui/icons-material'
import Paper from '../../components/common/Paper/Paper'
import { IconButton } from '@mui/material'
import EnquiryModal from '../../components/common/Modal/EnquiryModal'
import HelpCard from '../../components/Help/HelpCard'
import { BsTelephoneInbound } from 'react-icons/bs'
import { TbMessageShare } from 'react-icons/tb'
import ReferralCard from '../../components/MyPlans/ReferralCard'

const commonHelpRequests = [
  {
    icon: <ContactSupportOutlined />,
    label: 'Question about Coverage/Benefits',
  },
  {
    icon: <HelpOutlineOutlined />,
    label: 'Claim Issue/Question',
  },
  {
    icon: <ContactSupportOutlined />,
    label: 'Question about Premium/Billing',
  },
  {
    icon: <ContactSupportOutlined />,
    label: 'Request new ID Cards and Policy Packet',
  },
  {
    icon: <ContactSupportOutlined />,
    label: 'Shopping for Better Rate',
  },
  {
    icon: <ContactSupportOutlined />,
    label: 'Change Policy Effective Date',
  },
  {
    icon: <ContactSupportOutlined />,
    label: 'Requesting to Withdraw/Terminate',
  },
  {
    icon: <ContactSupportOutlined />,
    label: 'Change my Personal Information',
  },
]

const MainContainer = styled.div`
  max-width: 800px;
`

const Header = styled.div`
  color: ${theme.colors.textDark};
  h2 {
    font-size: 18px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    padding-top: 5px;
  }
  padding-bottom: 20px;
`

const Section = styled.div`
  padding-bottom: 40px;
`

const CommonHelpCard = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 15px 50px;
  color: ${theme.colors.textDark};
  transition: 0.4s background-color;
  border: 1px solid rgba(0, 0, 0, 0);
  @media screen and (max-width: 500px) {
    padding: 10px 20px;
  }

  &:hover {
    background-color: ${theme.colors.lightBack};
    border: 1px solid ${theme.colors.primary};
    transition: 0.4s border;
  }

  &:active {
    background-color: ${theme.colors.lightBack2};
    transition: 0.4s background-color;
  }

  h3 {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    grid-gap: 20px;

    span {
      display: flex;
      align-items: center;
      color: ${theme.colors.primary};
    }
  }
`

const HelpCardWrapper = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px 20px;
`

const ReachOut = () => {
  const [enquiryModal, setEnquiryModal] = React.useState({
    label: '',
    open: false,
  })
  const handleEnquiryModal = (label: string) => {
    setEnquiryModal((enquiryModal) => {
      return {
        open: !enquiryModal.open,
        label,
      }
    })
  }
  return (
    <MainContainer>
      <Section>
        <Header>
          <h2>Most Common Requests</h2>
          <p>
            Select if any of these apply to you and our Customer Support Agent
            will reach out to you
          </p>
        </Header>
        {commonHelpRequests.map((helpRequestObj, index) => (
          <Paper type='card' borderRadius='4px' margin='10px 0px' key={index}>
            <CommonHelpCard
              onClick={() => {
                handleEnquiryModal(helpRequestObj.label)
              }}
            >
              <h3>
                <span>{helpRequestObj.icon}</span>
                {helpRequestObj.label}
              </h3>
              <IconButton
                sx={{
                  width: '30px',
                  height: '30px',
                  background: theme.colors.primary,
                  '&:hover': {
                    background: theme.colors.primary,
                  },
                }}
              >
                <KeyboardArrowRight sx={{ color: theme.colors.textLight }} />
              </IconButton>
            </CommonHelpCard>
          </Paper>
        ))}
      </Section>
      <Section>
        <Header>
          <h2>We'd love to help you more</h2>
        </Header>
        <HelpCardWrapper>
          <HelpCard
            icon={<BsTelephoneInbound />}
            heading='Talk to our experts'
            buttonText='Get a Call Back'
            onClick={() => {
              handleEnquiryModal('Request Callback')
            }}
            textBody={
              <>
                <p>OR</p>
                <p>Dial (800) 845-2484</p>
              </>
            }
          />
          <HelpCard
            icon={<TbMessageShare />}
            heading='Chat with our experts'
            buttonText='Start Conversation'
            buttonColor='success'
            textBody={
              <>
                <p>
                  Or drop a message at any time and our customer support
                  executive will get back to you
                </p>
              </>
            }
          />
        </HelpCardWrapper>
      </Section>
      <ReferralCard />
      <EnquiryModal
        open={enquiryModal.open}
        heading={enquiryModal.label}
        handleClose={() => {
          setEnquiryModal((enquiryModal) => {
            return {
              open: !enquiryModal.open,
              label: '',
            }
          })
        }}
      />
    </MainContainer>
  )
}

export default ReachOut
