import styled from '@emotion/styled'
import React from 'react'
import theme from '../../theme/theme'
import {
  ContactSupportOutlined,
  HelpOutlineOutlined,
  KeyboardArrowRight,
} from '@mui/icons-material'
import Paper from '../../components/common/Paper/Paper'
import { IconButton } from '@mui/material'
import EnquiryModal from '../../components/common/Modal/EnquiryModal'
import HelpCard from '../../components/Help/HelpCard'
import { BsTelephoneInbound } from 'react-icons/bs'
import { TbMessageShare } from 'react-icons/tb'
import ReferralCard from '../../components/MyPlans/ReferralCard'
import HelpCaseCard from '../../components/Help/HelpCaseCard'

const MainContainer = styled.div`
  max-width: 800px;
`

const Header = styled.div`
  color: ${theme.colors.textDark};
  h2 {
    font-size: 18px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    padding-top: 5px;
  }
  padding-bottom: 20px;
`

const Section = styled.div`
  padding-bottom: 40px;
`

const HelpCardWrapper = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px 20px;
`

const MyCases = () => {
  const [enquiryModal, setEnquiryModal] = React.useState({
    label: '',
    open: false,
  })
  const handleEnquiryModal = (label: string) => {
    setEnquiryModal((enquiryModal) => {
      return {
        open: !enquiryModal.open,
        label,
      }
    })
  }
  return (
    <MainContainer>
      <Section>
        <Header>
          <h2>Ongoing Cases</h2>
        </Header>
        <HelpCaseCard />
        <HelpCaseCard />
        <HelpCaseCard />
        <HelpCaseCard />
      </Section>
      <Section>
        <ReferralCard />
      </Section>
      <Section>
        <Header>
          <h2>Resolved Cases</h2>
        </Header>
        <HelpCaseCard />
        <HelpCaseCard />
      </Section>
      <Section>
        <Header>
          <h2>We'd love to help you more</h2>
        </Header>
        <HelpCardWrapper>
          <HelpCard
            icon={<BsTelephoneInbound />}
            heading='Talk to our experts'
            buttonText='Get a Call Back'
            onClick={() => {
              handleEnquiryModal('Request Callback')
            }}
            textBody={
              <>
                <p>OR</p>
                <p>Dial (800) 845-2484</p>
              </>
            }
          />
          <HelpCard
            icon={<TbMessageShare />}
            heading='Chat with our experts'
            buttonText='Start Conversation'
            buttonColor='success'
            textBody={
              <>
                <p>
                  Or drop a message at any time and our customer support
                  executive will get back to you
                </p>
              </>
            }
          />
        </HelpCardWrapper>
      </Section>
      <ReferralCard />
      <EnquiryModal
        open={enquiryModal.open}
        heading={enquiryModal.label}
        handleClose={() => {
          setEnquiryModal((enquiryModal) => {
            return {
              open: !enquiryModal.open,
              label: '',
            }
          })
        }}
      />
    </MainContainer>
  )
}

export default MyCases
