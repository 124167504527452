import React from 'react'
import styled from '@emotion/styled'
import Heading from '../../../components/Profile/Heading'
import Section from '../../../components/Profile/Section'
import RadioGroup from '../../../components/common/RadioGroup/RadioGroup'
import Button from '../../../components/common/Button/Button'
import CheckboxGroup, {
  checkboxStateType,
} from '../../../components/common/CheckboxGroup/CheckboxGroup'
import ProfileUpdationSuccessModal from '../../../components/Profile/ProfileUpdationSuccessModal'

const MainContainer = styled.div`
  padding-bottom: 50px;
`

const HealthSection = () => {
  const [saveSuccessModalOpen, setSaveSuccessModalOpen] = React.useState(false)
  const [coverageCheckboxState, setCoverageCheckboxState] = React.useState<
    checkboxStateType[]
  >([
    {
      value: 'dental',
      label: 'Dental Coverage',
      checked: false,
      disabled: false,
    },
    {
      value: 'hearing',
      label: 'Hearing Coverage',
      checked: false,
      disabled: false,
    },
    {
      value: 'vision',
      label: 'Vision Coverage',
      checked: true,
      disabled: false,
    },
  ])

  const handleCoverageCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkedValue = e.target.value
    const updatedCoverageCheckboxState: checkboxStateType[] =
      coverageCheckboxState.map((obj) => {
        if (obj.value === checkedValue) {
          obj.checked = !obj.checked
        }
        return obj
      })

    setCoverageCheckboxState(() => updatedCoverageCheckboxState)
  }

  return (
    <MainContainer>
      <Heading>Health</Heading>
      <Section label='Do you consume Tobacco?'>
        <RadioGroup
          fontSize='14px'
          padding=' 5px 10px'
          radioButtons={[
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ]}
          onChange={(e) => {
            console.log(e.target.value)
          }}
          value=''
        />
      </Section>
      <Section label='How often do you need to see your doctor in a year?'>
        <RadioGroup
          fontSize='14px'
          padding=' 5px 10px'
          radioButtons={[
            {
              value: '5 in-network office visits 0 home Healthcare visits',
              label: (
                <>
                  5 in-network office visits <br /> 0 home Healthcare visits
                </>
              ),
            },
            {
              value: '10 in-network office visits 4 home Healthcare visits',
              label: (
                <>
                  10 in-network office visits <br /> 4 home Healthcare visits
                </>
              ),
            },
            {
              value: '20 in-network office visits 12 home Healthcare visits',
              label: (
                <>
                  20 in-network office visits <br /> 12 home Healthcare visits
                </>
              ),
            },
          ]}
          onChange={(e) => {
            console.log(e.target.value)
          }}
          value=''
        />
      </Section>
      <Section label='How often do you require inpatient admissions in a year?'>
        <RadioGroup
          fontSize='14px'
          padding=' 5px 10px'
          radioButtons={[
            {
              value:
                '0 inpatient admissions (1-5 days) 0 inpatient admissions (more than 5 days)',
              label: (
                <>
                  0 inpatient admissions (1-5 days) <br /> 0 inpatient
                  admissions (more than 5 days)
                </>
              ),
            },
            {
              value:
                '1 inpatient admissions (1-5 days) 0 inpatient admissions (more than 5 days)',
              label: (
                <>
                  0 inpatient admissions (1-5 days) <br /> 0 inpatient
                  admissions (more than 5 days)
                </>
              ),
            },
            {
              value:
                '1 inpatient admissions (1-5 days) 2 inpatient admissions (more than 5 days)',
              label: (
                <>
                  1 inpatient admissions (1-5 days) <br /> 2 inpatient
                  admissions (more than 5 days)
                </>
              ),
            },
          ]}
          onChange={(e) => {
            console.log(e.target.value)
          }}
          value=''
        />
      </Section>
      <Section label='How often do you require urgent and emergency care in a year?'>
        <RadioGroup
          fontSize='14px'
          padding=' 5px 10px'
          radioButtons={[
            {
              value: '1 urgent care visit 0 emergency room visits',
              label: (
                <>
                  1 urgent care visit <br /> 0 emergency room visits
                </>
              ),
            },
            {
              value: '0 urgent care visit 1 emergency room visits',
              label: (
                <>
                  0 urgent care visit <br /> 1 emergency room visits
                </>
              ),
            },
            {
              value: '0 urgent care visit 2 emergency room visits',
              label: (
                <>
                  0 urgent care visit <br /> 2 emergency room visits
                </>
              ),
            },
          ]}
          onChange={(e) => {
            console.log(e.target.value)
          }}
          value=''
        />
      </Section>
      <Section label='What coverage would you like to add to your plans?'>
        <CheckboxGroup
          onChange={handleCoverageCheckbox}
          checkboxState={coverageCheckboxState}
        />
      </Section>
      <Button
        margin='20px 0px 0px 0px'
        onClick={() =>
          setSaveSuccessModalOpen(
            (saveSuccessModalOpen) => !saveSuccessModalOpen
          )
        }
      >
        Save Changes
      </Button>

      <ProfileUpdationSuccessModal
        open={saveSuccessModalOpen}
        handleClose={() =>
          setSaveSuccessModalOpen(
            (saveSuccessModalOpen) => !saveSuccessModalOpen
          )
        }
      />
    </MainContainer>
  )
}

export default HealthSection
