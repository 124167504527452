import styled from '@emotion/styled'
import { Autocomplete, TextField as MuiTextField } from '@mui/material'
import React from 'react'
import theme from '../../theme/theme'
import Paper from '../common/Paper/Paper'

export type PharmacySearchOptionType = {
  id: number
  pharmacyName: string
  pharmacyAddress: string
  range: string
}

interface PharmacySearchFieldInterface {
  options: PharmacySearchOptionType[]
  placeholder?: string
}

const TextField = styled(MuiTextField)({
  '.MuiOutlinedInput-root': {
    fontSize: '16px',
    borderRadius: '35px',
  },
  '.MuiOutlinedInput-input': {},
  '.MuiInputLabel-root': {
    fontSize: '16px',
  },
  '.MuiInputLabel-shrink': {
    marginInlineStart: '-4px',
  },
})

const Option = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  h3 {
    font-size: 14px;
    color: ${theme.colors.primary};
    font-weight: 600;
  }
  p {
    font-size: 12px;
    color: ${theme.colors.textDark};
  }

  &:hover {
    background-color: ${theme.colors.lightBack2};
  }
`

const PharmacySearchField: React.FC<PharmacySearchFieldInterface> = ({
  options,
  placeholder,
}) => {
  return (
    <Autocomplete
      disablePortal
      options={options}
      getOptionLabel={(option) => option.pharmacyName}
      renderInput={(props) => (
        <TextField {...props} sx={{}} placeholder={placeholder} />
      )}
      renderOption={(props, option) => (
        <Option key={option.id}>
          <h3>{option.pharmacyName}</h3>
          <p>{option.pharmacyAddress}</p>
        </Option>
      )}
    />
  )
}

export default PharmacySearchField
