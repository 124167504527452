import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from '@mui/material'

import { GoKey as Key } from 'react-icons/go'
import React from 'react'
import { AiFillEye as Visibility } from 'react-icons/ai'
import { AiFillEyeInvisible as VisibilityOff } from 'react-icons/ai'
import styled from '@emotion/styled'
import theme from '../../../theme/theme'

interface InputFieldProps {
  label?: string
  width?: string
  fullWidth?: boolean
  color?: 'primary' | 'secondary' | 'info' | undefined
  error?: boolean
  value?: string
  helperText?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  superScript?: string
  margin?: string
  placeholder?: string
}

const Container = styled.div<{
  width: string
  fullWidth: boolean
  margin: string
}>`
  width: ${({ fullWidth, width }) => (fullWidth ? '100%' : width)};
  margin: ${({ margin }) => margin};
`

const TextField = styled(MuiTextField)({
  '.MuiOutlinedInput-root': {
    borderRadius: '35px',
  },
  '.MuiOutlinedInput-input': {},
  '.MuiInputLabel-root': {
    fontSize: '16px',
  },
  '.MuiInputLabel-shrink': {
    marginInlineStart: '-4px',
  },
})

const Label = styled.p`
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-left: 14px;
  color: ${theme.colors.textDark};
`
const SymbolWrapper = styled.span`
  color: ${theme.colors.primary};
  margin: 0px 5px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
`

const PasswordField: React.FC<InputFieldProps> = ({
  label,
  fullWidth = false,
  width = '265px',
  value,
  color = 'primary',
  error = false,
  helperText = '',
  onBlur,
  onChange,
  superScript,
  margin = '10px 0px',
  placeholder,
}) => {
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Container width={width} fullWidth={fullWidth} margin={margin}>
      {superScript && <Label>{superScript}</Label>}
      <TextField
        type={showPassword ? 'text' : 'password'}
        variant='outlined'
        color={color}
        label={label}
        margin='dense'
        value={value}
        error={error}
        helperText={helperText}
        onBlur={onBlur}
        onChange={onChange}
        size='small'
        fullWidth={true}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SymbolWrapper>
                <Key />
              </SymbolWrapper>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
              >
                {showPassword ? (
                  <Visibility style={{ color: theme.colors.primary }} />
                ) : (
                  <VisibilityOff style={{ color: theme.colors.primary }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Container>
  )
}

export default PasswordField
