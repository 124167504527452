const theme = {
  colors: {
    primary: '#222C69',
    secondary: '#BA0000',
    success: '#459308',
    warning: '#ff9800',
    error: '#BA0000',
    textLight: '#fff',
    textDark: '#2F3032',
    textGrey: '#515151',
    white: '#fff',
    borderColor: '#e5e5e5',
    errorBorder: 'rgba(186, 0, 0, 0.4)',
    lightBack: '#FBFBFB',
    lightBack2: '#F3F3F3',
    lightBack3: '#FAFAFA',
    secondaryBack: '#FFF8F8',
    secondaryBack2: 'rgba(186, 0, 0, 0.06)',
    primaryBack: '#F2F7FE',
    primaryBack2: '#e3eeff',
    primaryBack3: 'rgba(34, 44, 105, 0.06)',
  },
}

export default theme
