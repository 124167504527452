import styled from '@emotion/styled'
import React from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import theme from '../../../theme/theme'
import { IoMdArrowDropright as RightArrow } from 'react-icons/io'
import DropdownGroup from '../DropdownGroup/DropdownGroup'
import { useWindowWidth } from '../../../utils/hooks'
import { RxHamburgerMenu } from 'react-icons/rx'

interface SidebarLayoutProps {
  sidebarRoutes: Array<{
    id: number
    title: string
    route: string
    icon: JSX.Element
  }>
}

const MainContainer = styled.div`
  display: flex;
  grid-gap: 50px;
  padding: 50px 0px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding-top: 0px;
    grid-gap: 30px;
  }
`

const SidebarWrapper = styled.div`
  min-width: 250px;
  ::-webkit-scrollbar {
    height: 0px;
  }
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  .rightArrow {
    position: absolute;
    right: -20px;
    font-size: 32px;
    display: none;
  }
  .active {
    background-color: ${theme.colors.primaryBack2};
    font-weight: 700;
    .rightArrow {
      display: block;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: row;
    overflow: auto;
    padding-top: 20px;
    .active {
      .rightArrow {
        display: none;
      }
    }
  }

  @media screen and (max-width: 500px) {
    overflow: visible;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
`

const SidebarRoute = styled(NavLink)`
  position: relative;
  min-width: 180px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  border: 1px solid ${theme.colors.primary};
  padding: 10px 30px;
  text-decoration: none;
  font-size: 14px;
  color: ${theme.colors.primary};
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.primaryBack};
  }
  &:active {
    background-color: ${theme.colors.primaryBack2};
  }

  @media screen and (max-width: 500px) {
    padding: 10px 20px;
  }
`

const DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MobileHeader = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.colors.textDark};
`
const DropdownRouteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
`

const DropdownRoute = styled(NavLink)`
  min-width: 200px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  color: ${theme.colors.primary};
  border: 1px solid ${theme.colors.primary};
  display: flex;
  align-items: center;
  grid-gap: 10px;
`

const DropdownLabel = styled.div`
  padding: 5px;
  border: 1px solid ${theme.colors.primary};
  font-size: 12px;
  color: ${theme.colors.primary};
  font-weight: 600;
  &:active {
    background-color: ${theme.colors.primaryBack2};
  }
  &:hover {
    background-color: ${theme.colors.primaryBack2};
  }
`

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ sidebarRoutes }) => {
  const isMobile = useWindowWidth()
  const location = useLocation()
  return (
    <MainContainer>
      <SidebarWrapper>
        {!isMobile &&
          sidebarRoutes.map((sidebarRoute) => (
            <SidebarRoute
              key={sidebarRoute.id}
              to={sidebarRoute.route}
              className={({ isActive }) => (isActive ? 'active' : '')}
              end
            >
              {sidebarRoute.icon} {sidebarRoute.title}
              <RightArrow className='rightArrow' />
            </SidebarRoute>
          ))}
        {isMobile && (
          <DropdownWrapper>
            <MobileHeader>
              {location.pathname.split('/')[1].toUpperCase()}
            </MobileHeader>
            <DropdownLabel>
              <DropdownGroup
                dropdownLabel={
                  <>
                    <RxHamburgerMenu /> <span>More</span>
                  </>
                }
                variant='nav'
                arrow
                top='30px'
              >
                <DropdownRouteWrapper>
                  {sidebarRoutes.map((sidebarRoute) => (
                    <DropdownRoute
                      key={sidebarRoute.id}
                      to={sidebarRoute.route}
                      className={({ isActive }) => (isActive ? 'active' : '')}
                      end
                    >
                      {sidebarRoute.icon} {sidebarRoute.title}
                    </DropdownRoute>
                  ))}
                </DropdownRouteWrapper>
              </DropdownGroup>
            </DropdownLabel>
          </DropdownWrapper>
        )}
      </SidebarWrapper>
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
    </MainContainer>
  )
}

export default SidebarLayout
