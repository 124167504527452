import React from 'react'
import Paper from '../common/Paper/Paper'
import styled from '@emotion/styled'
import cigna from '../../assets/cignaLogo.png'
import theme from '../../theme/theme'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'

const MainWrapper = styled.div`
  padding: 20px 50px;

  @media screen and (max-width: 800px) {
    padding: 20px 20px;
  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
  color: ${theme.colors.textDark};
  font-size: 14px;
  div {
    display: flex;
    align-items: center;
  }

  .brand {
    flex-wrap: wrap;
    grid-gap: 10px 20px;
    h3 {
      font-size: 18px;
      font-weight: 400;
    }
    img {
      max-width: 80px;
    }
  }

  .status {
    grid-gap: 5px;
    .indicator {
      width: 15px;
      height: 15px;
      background-color: red;
      border-radius: 50% 50%;
    }
  }

  @media screen and (max-width: 800px) {
    .brand {
      display: block;
      h3 {
        display: none;
      }
      img {
        font-size: 60px;
      }
    }
    .status {
      min-width: fit-content;
      font-size: 12px;
      .indicator {
        width: 8px;
        height: 8px;
      }
    }
  }
`
const HeaderMobile = styled.h3`
  display: none;
  color: ${theme.colors.textDark};
  font-size: 16px;
  font-weight: 400;
  @media screen and (max-width: 800px) {
    display: block;
  }
`

const ContentWrapper = styled.div<{ mobileWrap?: boolean }>`
  padding: 20px 0px;
  grid-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 500px) {
    grid-template-columns: ${({ mobileWrap }) =>
      mobileWrap ? '1fr' : '1fr 1fr'};
  }
`

const Section = styled.div`
  color: ${theme.colors.textDark};
`

const Label = styled.div<{ bold?: boolean }>`
  font-size: 12px;
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  padding-bottom: 5px;
`

const Value = styled.div`
  font-size: 16px;
  font-weight: 700;
`
const CoverageWrapper = styled.div`
  display: flex;
  grid-gap: 5px 10px;
  flex-wrap: wrap;
  font-size: 14px;
  max-width: 250px;
  p {
    display: flex;
    align-items: center;
    grid-gap: 2px;
  }
`

const CloseIcon = styled(AiOutlineClose)`
  color: ${theme.colors.error};
  font-size: 16px;
`

const CheckIcon = styled(AiOutlineCheck)`
  color: ${theme.colors.success};
  font-size: 16px;
`

const PlanCard = () => {
  return (
    <>
      <Paper type='card'>
        <MainWrapper>
          <Header>
            <div className='brand'>
              <img src={cigna} alt='cigna logo' />
              <h3>Humana Walmart Value Rx Plan</h3>
            </div>
            <div className='status'>
              <div className='indicator' />
              <p>Pending Approval</p>
            </div>
          </Header>
          <HeaderMobile>Humana Walmart Value Rx Plan</HeaderMobile>
          <ContentWrapper>
            <Section>
              <Label>Coverage</Label>
              <Value>Medicare Advantage Plan</Value>
            </Section>
            <Section>
              <Label>Coverage type</Label>
              <Value>HMO</Value>
            </Section>
            <Section>
              <Label>Monthly Premium</Label>
              <Value>$26.00</Value>
            </Section>
            <Section>
              <Label>Effective Date</Label>
              <Value>January 1, 2023</Value>
            </Section>
          </ContentWrapper>
          <ContentWrapper mobileWrap>
            <Section>
              <Label bold>Doctors Coverage</Label>
              <CoverageWrapper>
                <p>
                  <CheckIcon /> Madison Smith
                </p>
                <p>
                  <CloseIcon /> George Scott
                </p>
                <p>
                  <CheckIcon /> A.R. George
                </p>
              </CoverageWrapper>
            </Section>
            <Section>
              <Label bold>Prescription Drug Coverage</Label>
              <CoverageWrapper>
                <p>
                  <CheckIcon /> Simvastatin
                </p>
                <p>
                  <CloseIcon /> Dolo 650
                </p>
                <p>
                  <CheckIcon /> Zocor
                </p>
                <p>
                  <CloseIcon /> Dolo 350
                </p>
              </CoverageWrapper>
            </Section>
          </ContentWrapper>
        </MainWrapper>
      </Paper>
    </>
  )
}

export default PlanCard
