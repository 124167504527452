const StringConstants = {
  COOKIE_TOKEN: 'token',
  UI_ERROR_MSG_FOR_INVALID_EMAIL: 'Please enter a valid email',
  UI_ERROR_MSG_FOR_INVALID_PASSWORD: 'Please enter a valid password',
  UI_ERROR_MSG_FOR_INVALID_CONFIRM_PASSWORD:
    'Password and confirm password fields must be identical',
  API_ERROR_MSG_FOR_FAILED_TO_RESET_PASSWORD:
    'Unable to reset password. Please try again later.',
  INTERNAL_SERVER_ERROR: 'Internal Server Error. Please try again later.',
  DEFAULT_PHONE_NUMBER: '(888) 335-8996',
}

export default StringConstants
