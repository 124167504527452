import styled from '@emotion/styled'
import React from 'react'
import Paper from '../Paper/Paper'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

interface DropDownGroupProps {
  children: React.ReactNode | JSX.Element | string | undefined
  dropdownLabel: JSX.Element | string | undefined
  variant?: 'nav' | 'generic'
  top?: string
  left?: string
  arrow?: boolean
  padding?: string
  snapBreakpoint?: string
}

const DropdownGroupWrapper = styled.div`
  position: relative;
  z-index: 100;
`

const DropdownLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
  cursor: pointer;

  .BsChevronDown {
    font-size: 14px;
  }
`

const DropdownWrapper = styled.div<{
  top?: string
  left?: string
  variant?: 'nav' | 'generic'
  snapBreakpoint?: string
}>`
  z-index: 100;
  position: absolute;
  top: ${({ variant, top }) => (top ? top : variant === 'nav' ? '50px' : '')};
  left: ${({ left }) => (left ? left : '0px')};

  @media screen and (max-width: ${({ snapBreakpoint }) => snapBreakpoint}) {
    top: ${({ top, variant }) => (top ? top : variant === 'nav' ? '45px' : '')};
    left: ${({ variant, left }) =>
      left ? left : variant === 'nav' ? 'auto' : '0px'};
    right: ${({ variant }) => (variant === 'nav' ? '0px' : '')};
  }

  @media screen and (max-width: 500px) {
    top: ${({ variant, top }) => (top ? top : variant === 'nav' ? '35px' : '')};
  }
`
const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  top: 0;
  left: 0;
  @media screen and (max-width: 800px) {
    background-color: rgba(0, 0, 0, 0.4);
  }
`

const DropdownGroup: React.FC<DropDownGroupProps> = ({
  children,
  dropdownLabel,
  variant = 'generic',
  top,
  left,
  arrow = false,
  padding = '20px',
  snapBreakpoint = '800px',
}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  return (
    <>
      <DropdownGroupWrapper>
        <DropdownLabelWrapper
          onClick={() => {
            setDropdownOpen(!dropdownOpen)
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') setDropdownOpen(!dropdownOpen)
          }}
        >
          {dropdownLabel}
          {arrow && !dropdownOpen && <BsChevronDown />}
          {arrow && dropdownOpen && <BsChevronUp />}
        </DropdownLabelWrapper>
        {dropdownOpen && (
          <DropdownWrapper
            variant={variant}
            top={top}
            left={left}
            snapBreakpoint={snapBreakpoint}
          >
            <Paper
              padding={padding}
              boxShadow='0px 4px 8px 4px rgba(0, 0, 0, 0.1)'
              type='card'
            >
              {children}
            </Paper>
          </DropdownWrapper>
        )}
        {dropdownOpen && (
          <Overlay
            onClick={() => {
              setDropdownOpen(!dropdownOpen)
            }}
          />
        )}
      </DropdownGroupWrapper>
    </>
  )
}

export default DropdownGroup
