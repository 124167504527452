import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import {
  CardWrapper,
  Footer,
  Form,
  LogoWrapper,
  MainContainer,
  Wrapper,
} from '../Login/LoginStyled'

import Button from '../../components/common/Button/Button'
import InputField from '../../components/common/Input/InputField'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import Paper from '../../components/common/Paper/Paper'
import React from 'react'
import StringConstants from '../../constants/StringConstants'
import ValidationUtils from '../../utils/validation/ValidationUtils'
import eipFlag from '../../assets/eipFlag.png'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useWindowWidth } from '../../utils/hooks'

const ButtonWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0px;
`

/*
  BUSINESS LOGIC:

  user comes to signup page, when he submits the email,
  1. if user exists && a customer portal user -> we'll redirect him to sign in and respond that email is already signed up.
  2. if user exists && not a customer portal user -> we'll email him the credentials to login.
  3. if user doesn't exist, we'll redirect him to one of the landing pages.
   */

const Signup = () => {
  const isMobile = useWindowWidth()
  const navigate = useNavigate()

  const [state, setState] = React.useState({
    isLoading: false,
    email: '',
    emailError: false,
  })

  const isValidEmail = (email: string) => {
    return ValidationUtils.validateEmail(email)
  }

  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let email = isEmpty(e.target.value) ? '' : e.target.value
    setState({
      ...state,
      email,
      emailError: !isValidEmail(email),
    })
  }

  const handleSignup = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    let emailError = !isValidEmail(state.email)
    if (emailError) {
      setState({
        ...state,
        emailError,
      })
    } else {
      setState({
        ...state,
        emailError: false,
        isLoading: true,
      })

      // signup business logic here
    }
  }

  return (
    <MainContainer>
      <Wrapper>
        <LogoWrapper>
          <img src={eipFlag} alt='eip flag' />
        </LogoWrapper>
        <Paper
          type='card'
          margin='20px 0px'
          padding='20px'
          boxShadow='0px 0px 12px -2px rgba(0, 0, 0, 0.05)'
        >
          <CardWrapper>
            <h2>Create Your Account</h2>
            <Form>
              <InputField
                type='email'
                margin='20px 0px 0px 0px'
                placeholder='Your email'
                superScript='Enter your email'
                onChange={handleEmailInput}
                value={state.email || ''}
                symbol={<MailOutlineIcon />}
                fullWidth
                error={state.emailError}
                helperText={
                  state.emailError
                    ? StringConstants.UI_ERROR_MSG_FOR_INVALID_EMAIL
                    : ''
                }
              />
              <ButtonWrapper isMobile={isMobile}>
                <Button
                  startIcon={<AiOutlineLeft style={{ fontSize: '12px' }} />}
                  width={isMobile ? '100%' : '150px'}
                  variant='outlined'
                  onClick={() => {
                    navigate(-1)
                  }}
                  disabled={state.isLoading}
                  fontSize='12px'
                >
                  Back
                </Button>
                <Button
                  endIcon={<AiOutlineRight style={{ fontSize: '12px' }} />}
                  width={isMobile ? '100%' : '150px'}
                  onClick={handleSignup}
                  loading={state.isLoading}
                  disabled={state.isLoading}
                  fontSize='12px'
                >
                  Create Account
                </Button>
              </ButtonWrapper>
            </Form>
          </CardWrapper>
        </Paper>
        <Footer>
          <span>Privacy Policy</span>
          <span>Terms of Service</span>
        </Footer>
      </Wrapper>
    </MainContainer>
  )
}

export default Signup
