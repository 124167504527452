import { AiOutlineRight } from 'react-icons/ai'
import { BsCheckCircleFill } from 'react-icons/bs'
import Button from '../../components/common/Button/Button'
import styled from '@emotion/styled'
import theme from '../../theme/theme'
import { useNavigate } from 'react-router-dom'
import RouteConstants from '../../constants/RouteConstants'

const Content = styled.div`
  text-align: left;
  padding: 20px 30px;
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: ${theme.colors.textDark};
    padding-bottom: 20px;
  }
  p {
    color: ${theme.colors.textDark};
    font-size: 14px;
  }
`
const TickIcon = styled(BsCheckCircleFill)`
  color: ${theme.colors.success};
  font-size: 15px;
  margin-left: 10px;
  @media screen and (max-width: 500px) {
    margin-left: 0px;
  }
`
const EmailSent = () => {
  const navigate = useNavigate()
  return (
    <>
      <Content>
        <h3>
          We have sent you an email with the password reset link. <TickIcon />
        </h3>
        <p>Don’t forget to check your spam folder</p>
      </Content>

      <Button
        fullWidth
        color='primary'
        endIcon={<AiOutlineRight />}
        margin='20px 0px'
        onClick={() => {
          navigate(RouteConstants.LOGIN)
        }}
      >
        Back to Sign In page
      </Button>
    </>
  )
}

export default EmailSent
