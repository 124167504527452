import React from 'react'
import { Modal as MuiModal } from '@mui/material'
import styled from '@emotion/styled'
import Paper from '../Paper/Paper'
import { GrClose } from 'react-icons/gr'
import theme from '../../../theme/theme'

interface ModalProps {
  open: boolean
  handleClose: () => void
  children: React.ReactNode | JSX.Element | string | undefined
  heading?: string
  width?: number
}

const Wrapper = styled.div<{ width: number }>`
  outline: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ width }) => width + 'px'};

  @media screen and (max-width: ${({ width }) => width + 50 + 'px'}) {
    width: 100%;
    padding: 20px;
  }
`
const Header = styled.div<{ heading: string }>`
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  padding: ${({ heading }) => (heading ? '20px' : '20px 20px 0px 20px')};
  h3 {
    color: ${theme.colors.primary};
    font-size: 18px;
  }
  span {
    display: flex;
    cursor: pointer;
    padding: 5px;
    border-radius: 50% 50%;
    font-size: 18px;
    &:active {
      background-color: ${theme.colors.primaryBack};
    }
  }
`
const ContentWrapper = styled.div`
  padding: 0px 20px 20px 20px;
  max-height: 80vh;
  overflow-y: auto;
`

const Modal: React.FC<ModalProps> = ({
  children,
  open,
  handleClose,
  heading = '',
  width = 500,
}) => {
  return (
    <MuiModal open={open} onClose={handleClose}>
      <Wrapper width={width}>
        <Paper type='card'>
          <Header heading={heading}>
            <h3>{heading}</h3>
            <span onClick={handleClose}>
              <GrClose />
            </span>
          </Header>
          <ContentWrapper>{children}</ContentWrapper>
        </Paper>
      </Wrapper>
    </MuiModal>
  )
}

export default Modal
