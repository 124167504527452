import React from 'react'
import Paper from '../common/Paper/Paper'
import styled from '@emotion/styled'
import theme from '../../theme/theme'
import { IoCloseCircle } from 'react-icons/io5'
import Button from '../common/Button/Button'
import { useWindowWidth } from '../../utils/hooks'
import { BsChevronRight } from 'react-icons/bs'

interface NotCoveredCardProps {
  heading: string
  covered?: boolean
  description: string
}

const ContentWrapper = styled.div<{ covered: boolean }>`
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: ${theme.colors.textDark};
  }

  p {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: ${theme.colors.textDark};
  }

  .special {
    font-weight: 600;
    color: ${theme.colors.secondary};
    grid-gap: 5px;
  }

  @media screen and (max-width: 800px) {
    padding: 20px 20px;
  }
`

const NotCoveredCard: React.FC<NotCoveredCardProps> = ({
  heading,
  covered = true,
  description,
}) => {
  const isMobile = useWindowWidth()
  return (
    <>
      <Paper margin='15px 0px' variant='error' borderRadius='4px'>
        <ContentWrapper covered={covered}>
          <h3>{heading}</h3>
          <p className='special'>
            <IoCloseCircle /> You don’t have this coverage
          </p>
          <p>{description}</p>
          <Button
            height='40px'
            fullWidth={isMobile}
            fontSize='14px'
            endIcon={<BsChevronRight style={{ fontSize: '14px' }} />}
          >
            Find out more
          </Button>
        </ContentWrapper>
      </Paper>
    </>
  )
}

export default NotCoveredCard
