import React from 'react'
import Paper from '../common/Paper/Paper'
import styled from '@emotion/styled'
import theme from '../../theme/theme'
import { HiOutlineTicket } from 'react-icons/hi'
import { FaEdit } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import { TbCircleFilled } from 'react-icons/tb'

const HelpCaseCardWrapper = styled.div`
  padding: 20px 50px;

  @media screen and (max-width: 500px) {
    padding: 20px 20px;
  }
`

const Heading = styled.div`
  color: ${theme.colors.textDark};
  display: flex;
  flex-wrap: wrap-reverse;
  grid-gap: 10px 20px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  div {
    h3 {
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 10px;
    }
    p {
      font-size: 14px;
    }
  }
`

const Ticket = styled.div`
  color: ${theme.colors.textDark};
  font-size: 12px;
  display: flex;
  grid-gap: 5px;
  align-items: center;
  font-weight: 600;
`

const InfoText = styled.div`
  padding: 20px 0px;
  font-size: 14px;
  color: ${theme.colors.primary};
  p {
    span {
      font-weight: 700;
    }
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    .primary {
      color: ${theme.colors.primary};
      grid-gap: 2px;
      border-bottom: 1px solid ${theme.colors.primary};
    }
    .secondary {
      color: ${theme.colors.secondary};
      border-bottom: 1px solid ${theme.colors.secondary};
    }
    p {
      display: flex;
      font-size: 14px;
      align-items: center;
      cursor: pointer;
      &:hover {
        border: none;
      }
    }
  }

  .status {
    grid-gap: 10px;
    font-size: 14px;
    color: ${theme.colors.textDark};
  }
`

const HelpCaseCard = () => {
  return (
    <Paper type='card' margin='10px 0px'>
      <HelpCaseCardWrapper>
        <Heading>
          <div>
            <h3>Request for Address Change</h3>
            <p>Please change my address to 465 36th St, Brooklyn, NY 11232</p>
          </div>
          <div>
            <Paper padding='5px 20px' backgroundColor={theme.colors.lightBack2}>
              <Ticket>
                <HiOutlineTicket
                  style={{ fontSize: '16px', color: theme.colors.secondary }}
                />
                <span>Ticket #34567</span>
              </Ticket>
            </Paper>
          </div>
        </Heading>
        <InfoText>
          <p>
            Our Customer Support Agent will reach out to you on{' '}
            <span>(914) 854-6286</span> between{' '}
            <span>9:00 PM and 12:00 AM</span>
          </p>
        </InfoText>
        <Footer>
          <div>
            <p className='primary'>
              <FaEdit /> Modify
            </p>
            <p className='secondary'>
              <AiOutlineClose /> Cancel
            </p>
          </div>
          <div className='status'>
            <TbCircleFilled style={{ color: theme.colors.warning }} /> Ongoing
          </div>
        </Footer>
      </HelpCaseCardWrapper>
    </Paper>
  )
}

export default HelpCaseCard
