import jwtDecode, { JwtPayload } from 'jwt-decode'

import StringConstants from '../constants/StringConstants'
import cookie from 'react-cookies'

const isAuthenticatedUser = () => {
  try {
    const token = cookie.load(StringConstants.COOKIE_TOKEN)
    if (typeof token !== typeof undefined) {
      const decoded = jwtDecode(token) as JwtPayload
      var currentTime = Date.now() / 1000
      if (decoded && decoded.exp && decoded.exp > currentTime) return true
    }
    console.log('we got hereeee')
    return false
  } catch (err) {
    return false
  }
}

const getUserDataFromCookie = () => {
  const token = cookie.load(StringConstants.COOKIE_TOKEN)
  if (token) {
    const decoded = jwtDecode(token)
    return decoded
  }
  return {}
}

function saveToken(jwtToken: string | object) {
  cookie.save(StringConstants.COOKIE_TOKEN, jwtToken, {})
}

const deleteToken = () => {
  cookie.remove(StringConstants.COOKIE_TOKEN, { path: '/' })
}

export { isAuthenticatedUser, getUserDataFromCookie, saveToken, deleteToken }
