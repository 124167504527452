import styled from '@emotion/styled'
import React from 'react'
import theme from '../../theme/theme'
import { BsTelephoneInbound } from 'react-icons/bs'
import { TbMessageShare } from 'react-icons/tb'
import { IconButton } from '@mui/material'
import EnquiryModal from '../common/Modal/EnquiryModal'

const MainContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 12px;
    color: ${theme.colors.primary};
    font-weight: 700;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 500px) {
    bottom: 5px;
    right: 5px;
    h3 {
      display: none;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FloatingHelpButtons = () => {
  const [enquiryModalOpen, setEnquiryModalOpen] = React.useState(false)
  return (
    <MainContainer>
      <h3>Need Help?</h3>
      <ButtonWrapper>
        <IconButton
          onClick={() => {
            setEnquiryModalOpen((enquiryModalOpen) => !enquiryModalOpen)
          }}
          sx={{
            padding: '15px',
            margin: '2.5px',
            background: theme.colors.primary,
            border: `1px solid ${theme.colors.borderColor}`,
            '&:hover': { background: theme.colors.primary },
          }}
        >
          <BsTelephoneInbound
            style={{ color: theme.colors.textLight, fontSize: '18px' }}
          />
        </IconButton>

        <IconButton
          sx={{
            padding: '15px',
            margin: '2.5px',
            background: theme.colors.success,
            border: `1px solid ${theme.colors.borderColor}`,
            '&:hover': { background: theme.colors.success },
          }}
        >
          <TbMessageShare
            style={{ color: theme.colors.textLight, fontSize: '18px' }}
          />
        </IconButton>
      </ButtonWrapper>
      <EnquiryModal
        open={enquiryModalOpen}
        handleClose={() => {
          setEnquiryModalOpen((enquiryModalOpen) => !enquiryModalOpen)
        }}
        heading='Request Callback'
      />
    </MainContainer>
  )
}

export default FloatingHelpButtons
