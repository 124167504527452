import styled from '@emotion/styled'
import React from 'react'
import theme from '../../../theme/theme'
import { HiOutlineTicket } from 'react-icons/hi'

const Container = styled.div`
  padding: 20px 30px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  &:hover {
    background-color: ${theme.colors.primaryBack};
  }
`
const Header = styled.h4`
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.textDark};
  padding-bottom: 10px;
`
const TicketWrapper = styled.div`
  display: flex;
`

const Ticket = styled.p`
  background-color: ${theme.colors.lightBack2};
  padding: 5px 10px;
  border-radius: 2px;
  border: 1px solid ${theme.colors.borderColor};
  font-size: 10px;
  color: ${theme.colors.textDark};
  font-weight: 600;
  display: flex;
  align-items: center;
  grid-gap: 5px;

  .HiOutlineTicket {
    color: ${theme.colors.secondary};
    font-size: 18px;
  }
`
const Footer = styled.div`
  padding-top: 10px;
  font-size: 10px;
  color: ${theme.colors.textDark};
`

const Notification = () => {
  return (
    <Container>
      <Header>Your address was changed successfully!</Header>
      <TicketWrapper>
        <Ticket>
          <HiOutlineTicket className='HiOutlineTicket' />
          <span>Ticket #3456</span>
        </Ticket>
      </TicketWrapper>
      <Footer>
        <span>02/12/2022 12:34 PM</span>
      </Footer>
    </Container>
  )
}

export default Notification
