import MuiButton from '@mui/material/Button'
import React from 'react'
import SyncLoader from 'react-spinners/SyncLoader'
import theme from '../../../theme/theme'
import styled from '@emotion/styled'

interface ButtonProps {
  variant?: 'text' | 'outlined' | 'contained' | undefined
  textTransform?: 'capitalize' | 'uppercase' | 'lowercase' | undefined
  fullWidth?: boolean
  color?: 'primary' | 'secondary' | 'info' | 'success' | undefined
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  children?: React.ReactNode | JSX.Element | string | undefined
  bold?: boolean
  type?: 'button' | 'reset' | 'submit' | undefined
  disabled?: boolean
  width?: string
  fontSize?: string
  height?: string
  borderRadius?: string
  justifyContent?: 'flex-start' | 'flex-end' | 'center'
  margin?: string
  underlineText?: boolean
  value?: string
  href?: string
  endIcon?: JSX.Element
  startIcon?: JSX.Element
  loading?: boolean
  id?: string
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement> | undefined
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const SpanWrap = styled.span`
  display: flex;
  align-items: center;
`

const Button: React.FC<ButtonProps> = ({
  variant = 'contained',
  fullWidth = false,
  color = 'primary',
  onClick,
  children,
  bold = true,
  type = 'button',
  underlineText = false,
  disabled = false,
  width = '265px',
  textTransform = 'capitalize',
  fontSize = '16px',
  height = '42px',
  borderRadius = '36px',
  justifyContent = 'center',
  margin = '5px 0px',
  value,
  href,
  endIcon,
  startIcon,
  loading = false,
  id,
  onMouseLeave,
  onMouseEnter,
}) => {
  return (
    <MuiButton
      variant={variant}
      disableElevation
      color={color}
      fullWidth={fullWidth}
      onClick={(e) => {
        onClick && onClick(e)
      }}
      id={id}
      type={type}
      disabled={disabled}
      value={value}
      href={href}
      endIcon={endIcon}
      startIcon={startIcon}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        fontWeight: bold ? '600' : 'normal',
        minWidth: fullWidth ? '100%' : width,
        borderRadius: borderRadius,
        textTransform: textTransform,
        fontSize: fontSize,
        margin: margin,
        minHeight: height,
      }}
      style={{
        justifyContent: justifyContent,
        paddingTop: '0px',
        paddingBottom: '0px',
        fontSize: fontSize,
        color: color,
        textDecoration: underlineText ? 'underline' : 'none',
      }}
    >
      {loading ? (
        <SyncLoader loading={true} color={theme.colors.white} size={4} />
      ) : (
        <SpanWrap>{children}</SpanWrap>
      )}
    </MuiButton>
  )
}

export default Button
