import styled from '@emotion/styled'
import React from 'react'
import Paper from '../common/Paper/Paper'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import theme from '../../theme/theme'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import happyFamily from '../../assets/happyFamily.svg'
import { BsArrowRight } from 'react-icons/bs'

const CarouselWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const CarouselItemWrapper = styled.div`
  padding: 20px 100px;
  @media screen and (max-width: 1250px) {
    padding: 20px 50px;
  }
`

const Prev = styled.div`
  position: absolute;
  left: 10px;
  background-color: ${theme.colors.white};
  padding: 5px;
  border-radius: 50% 50%;
  border: 1px solid ${theme.colors.primary};
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.primaryBack};
  }
`

const Next = styled.div`
  position: absolute;
  right: 10px;
  background-color: ${theme.colors.white};
  padding: 5px;
  border-radius: 50% 50%;
  border: 1px solid ${theme.colors.primary};
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.primaryBack};
  }
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  .textWrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    justify-content: space-between;
    color: ${theme.colors.primary};
    max-width: 400px;

    h3 {
      font-weight: 600;
      font-size: 18px;
    }

    p {
      text-decoration: underline;
      font-weight: 700;
      font-size: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      grid-gap: 5px;
      transition: grid-gap 0.2s;
      &:hover {
        grid-gap: 10px;
        transition: grid-gap 0.2s;
        color: ${theme.colors.secondary};
      }
    }
  }

  .imageWrapper {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 1000px) {
    .textWrapper {
      h3 {
        font-size: 14px;
      }
      p {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .imageWrapper {
      display: none;
    }
  }
`

const PlanCarousel = () => {
  const slider = React.useRef<any>(null)
  return (
    <>
      <Paper
        type='card'
        variant='gradient'
        padding='0px 20px'
        margin='20px 0px'
      >
        <CarouselWrapper>
          <Slider
            ref={slider}
            nextArrow={<></>}
            prevArrow={<></>}
            adaptiveHeight
          >
            <CarouselItemWrapper>
              <Item>
                <div className='textWrapper'>
                  <h3>
                    Protect your financial future by enrolling in a Cancer,
                    Heart Attack and Stroke policy
                  </h3>
                  <p>
                    Find out more <BsArrowRight />
                  </p>
                </div>
                <div className='imageWrapper'>
                  <img src={happyFamily} alt='happy family' />
                </div>
              </Item>
            </CarouselItemWrapper>
            <CarouselItemWrapper>
              <Item>
                <div className='textWrapper'>
                  <h3>
                    Protect your financial future by enrolling in a Dental,
                    Vision & Hearing policy
                  </h3>
                  <p>
                    Find out more <BsArrowRight />
                  </p>
                </div>
                <div className='imageWrapper'>
                  <img src={happyFamily} alt='happy family' />
                </div>
              </Item>
            </CarouselItemWrapper>
          </Slider>
          <Prev onClick={() => slider?.current?.slickPrev()}>
            <AiOutlineLeft />
          </Prev>
          <Next onClick={() => slider?.current?.slickNext()}>
            <AiOutlineRight />
          </Next>
        </CarouselWrapper>
      </Paper>
    </>
  )
}

export default PlanCarousel
