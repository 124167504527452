import styled from '@emotion/styled'
import React from 'react'
import Heading from '../../../components/Profile/Heading'
import Section from '../../../components/Profile/Section'
import InputField from '../../../components/common/Input/InputField'
import { useWindowWidth } from '../../../utils/hooks'
import Button from '../../../components/common/Button/Button'

const MainContainer = styled.div`
  padding-bottom: 50px;
`

const ContactSection = () => {
  const isMobile = useWindowWidth()
  return (
    <MainContainer>
      <Heading>Contact & Address</Heading>
      <Section label='' divider>
        <InputField
          type='text'
          placeholder='enter your full address'
          superScript='Your full address'
          fullWidth
        />
        <div
          style={{
            display: 'flex',
            gridGap: '0px 20px',
            alignItems: 'flex-end',
          }}
        >
          <InputField
            placeholder='zipcode'
            superScript='Your Zip code'
            type='text'
            width='180px'
            fullWidth={isMobile}
          />
          <InputField
            placeholder='county'
            type='text'
            width='180px'
            fullWidth={isMobile}
          />
        </div>
        <Button margin='20px 0px 0px 0px' fontSize='14px'>
          Change my Address Information
        </Button>
      </Section>
      <Section label='' divider>
        <InputField
          placeholder='Enter your email'
          superScript='Your E-mail'
          type='text'
          fullWidth
        />
      </Section>
      <Section label=''>
        <InputField
          placeholder='Enter your phone number'
          superScript='Your Phone'
          type='text'
          fullWidth
        />
        <InputField
          placeholder='Enter your alternate phone number'
          superScript='Alternate Phone number (optional)'
          type='text'
          fullWidth
        />
      </Section>
      <Button margin='20px 0px 0px 0px' fontSize='14px'>
        Save Changes
      </Button>
    </MainContainer>
  )
}

export default ContactSection
