import React from 'react'
import Modal from '../common/Modal/Modal'
import styled from '@emotion/styled'
import theme from '../../theme/theme'
import { SiGmail } from 'react-icons/si'
import { FaFacebookSquare } from 'react-icons/fa'
import Paper from '../common/Paper/Paper'
import Button from '../common/Button/Button'

interface ReferralModalProps {
  open: boolean
  handleClose: () => void
}

const Wrapper = styled.div`
  p {
    font-size: 14px;
    color: ${theme.colors.textDark};
    padding: 20px 0px;
  }
`
const SocialWrapper = styled.div`
  cursor: pointer;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  font-size: 14px;
  transition: background-color 0.2s;
  border-radius: 5px;

  .socialIcon {
    font-size: 24px;
    color: ${theme.colors.primary};
  }

  &:hover {
    background-color: ${theme.colors.primaryBack};
    transition: background-color 0.2s;
  }

  &:active {
    background-color: ${theme.colors.primaryBack2};
    transition: background-color 0.2s;
  }
`

const ReferralModal: React.FC<ReferralModalProps> = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      heading='Thank You for Caring!'
    >
      <Wrapper>
        <p>
          We would be thrilled if you took a moment to tell your friends &
          family!
        </p>
        <Paper margin='5px' borderRadius='5px'>
          <SocialWrapper>
            <SiGmail className='socialIcon' />
            Recommend on Email
          </SocialWrapper>
        </Paper>
        <Paper margin='5px' borderRadius='5px'>
          <SocialWrapper>
            <FaFacebookSquare className='socialIcon' />
            Recommend on Facebok
          </SocialWrapper>
        </Paper>
        <Button
          onClick={handleClose}
          variant='outlined'
          fullWidth
          height='40px'
          margin='50px 0px 0px 0px'
        >
          Close
        </Button>
      </Wrapper>
    </Modal>
  )
}

export default ReferralModal
