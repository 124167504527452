import React from 'react'
import SidebarLayout from '../../components/common/Layout/SidebarLayout'
import RouteConstants from '../../constants/RouteConstants'
import { BiUser } from 'react-icons/bi'
import { LocalPharmacy } from '@mui/icons-material'
import { HiHeart, HiOutlineDocumentReport } from 'react-icons/hi'
import { RiNurseFill } from 'react-icons/ri'
import styled from '@emotion/styled'
import SpouseOnboardingCard from '../../components/Profile/SpouseOnboardingCard'

const MeRoutes = [
  {
    id: 1,
    title: 'Profile',
    route: RouteConstants.PROFILE_SPOUSE,
    icon: <BiUser style={{ fontSize: '18px' }} />,
  },
  {
    id: 2,
    title: 'Health',
    route: RouteConstants.PROFILE_SPOUSE_HEALTH,
    icon: <HiHeart style={{ fontSize: '18px' }} />,
  },
  {
    id: 3,
    title: 'Prescriptions',
    route: RouteConstants.PROFILE_SPOUSE_PRESCRIPTION,
    icon: <HiOutlineDocumentReport style={{ fontSize: '18px' }} />,
  },
  {
    id: 4,
    title: 'Doctors',
    route: RouteConstants.PROFILE_SPOUSE_DOCTOR,
    icon: <RiNurseFill style={{ fontSize: '18px' }} />,
  },
  {
    id: 5,
    title: 'Pharmacy',
    route: RouteConstants.PROFILE_SPOUSE_PHARMACY,
    icon: <LocalPharmacy style={{ fontSize: '18px' }} />,
  },
]

const SpouseOnboardingCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0px;
`

const Spouse = () => {
  const spousePresent = false
  return (
    <>
      {spousePresent ? (
        <SidebarLayout sidebarRoutes={MeRoutes} />
      ) : (
        <SpouseOnboardingCardWrapper>
          <SpouseOnboardingCard />
        </SpouseOnboardingCardWrapper>
      )}
    </>
  )
}

export default Spouse
