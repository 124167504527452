import styled from '@emotion/styled'
import React from 'react'
import Paper from '../common/Paper/Paper'
import theme from '../../theme/theme'
import Button from '../common/Button/Button'

interface HelpCardProps {
  icon?: any
  heading?: string
  buttonText?: string
  buttonColor?: 'primary' | 'secondary' | 'success'
  textBody?: any
  onClick?: () => void
}

const MainContainer = styled.div`
  flex: 1;
  min-width: 300px;
`

const Circle = styled.div`
  width: 200px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  position: absolute;
  top: -40px;
  right: -20px;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.primary};
  font-size: 42px;
`

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px;
`

const Heading = styled.h3`
  font-size: 16px;
  color: ${theme.colors.primary};
  font-weight: 700;
  padding-bottom: 40px;
`

const Text = styled.div`
  color: ${theme.colors.primary};
  font-weight: 600;
  padding-top: 10px;
  p {
    font-size: 14px;
    line-height: 16px;
  }
`

const HelpCard: React.FC<HelpCardProps> = ({
  icon,
  heading,
  buttonText,
  textBody,
  buttonColor = 'primary',
  onClick,
}) => {
  return (
    <MainContainer>
      <Paper type='card' borderRadius='4px' height='100%'>
        <Wrapper>
          <Heading>{heading}</Heading>
          <Button
            height='36px'
            fontSize='12px'
            width='180px'
            startIcon={icon}
            color={buttonColor}
            onClick={onClick}
          >
            {buttonText}
          </Button>
          <Text>{textBody}</Text>
          <Circle>{icon}</Circle>
        </Wrapper>
      </Paper>
    </MainContainer>
  )
}

export default HelpCard
