import styled from '@emotion/styled'
import React from 'react'
import theme from '../../theme/theme'
import { useWindowWidth } from '../../utils/hooks'
import { useLocation } from 'react-router-dom'
import DropdownGroup from '../common/DropdownGroup/DropdownGroup'
import { RxHamburgerMenu } from 'react-icons/rx'

const MainContainer = styled.div`
  max-width: 280px;
  flex-shrink: 0;
`

const Section = styled.div`
  padding-bottom: 30px;
`

const Heading = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.colors.textDark};
  padding-bottom: 10px;

  @media screen and (max-width: 1000px) {
    font-size: 16px;
    padding-bottom: 5px;
    font-weight: 600;
  }
`

const Item = styled.p<{ covered: boolean }>`
  padding: 10px 20px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: ${({ covered }) =>
    covered ? theme.colors.primary : theme.colors.secondary};
  border-left: 3px solid
    ${({ covered }) =>
      covered ? theme.colors.primary : theme.colors.secondary};
  &:hover {
    background-color: ${({ covered }) =>
      covered ? theme.colors.primaryBack3 : theme.colors.secondaryBack2};
  }

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    padding: 10px 10px;
    margin-bottom: 5px;
    font-weight: 400;
    background-color: ${({ covered }) =>
      covered ? theme.colors.primaryBack : theme.colors.secondaryBack};
  }
`

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
const MobileHeader = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.colors.textDark};
`
const DropdownLabel = styled.div`
  padding: 5px;
  border: 1px solid ${theme.colors.primary};
  font-size: 12px;
  color: ${theme.colors.primary};
  font-weight: 600;
  &:active {
    background-color: ${theme.colors.primaryBack2};
  }
  &:hover {
    background-color: ${theme.colors.primaryBack2};
  }
`
const MainContainerCollapsed = styled.div`
  min-width: 280px;
  max-height: 400px;
  overflow: auto;
  padding: 0px 20px;
`

const PlanInfoContent = () => {
  return (
    <>
      <Section>
        <Heading>Core Plans</Heading>
        <Item covered>Medicare Advantage Plan (Part C)</Item>
      </Section>
      <Section>
        <Heading>Ancillary Plans</Heading>
        <Item covered={false}>Hospital Indemnity - Not Covered</Item>
        <Item covered={true}>Dental, Vision and Hearing - Not Covered</Item>
        <Item covered={false}>
          Cancer, Heart Attack and Stroke - Not Covered
        </Item>
      </Section>
      <Section>
        <Heading>Life Insurance</Heading>
        <Item covered>Life Insurance</Item>
      </Section>
    </>
  )
}

const MyPlanInfo = () => {
  const collapseBreakpoint = useWindowWidth(1000)
  const location = useLocation()
  return (
    <>
      {!collapseBreakpoint ? (
        <MainContainer>
          <PlanInfoContent />
        </MainContainer>
      ) : (
        <DropdownWrapper>
          <MobileHeader>
            {location.pathname.split('/')[1].toUpperCase()}
          </MobileHeader>
          <DropdownLabel>
            <DropdownGroup
              dropdownLabel={
                <>
                  <RxHamburgerMenu /> <span>My Plan Summary</span>
                </>
              }
              variant='nav'
              arrow
              top='30px'
              padding='20px 0px'
              snapBreakpoint='1000px'
            >
              <MainContainerCollapsed>
                <PlanInfoContent />
              </MainContainerCollapsed>
            </DropdownGroup>
          </DropdownLabel>
        </DropdownWrapper>
      )}
    </>
  )
}

export default MyPlanInfo
