const UrlConstants: { [key: string]: { USECASE: string; URL: string } } = {
  CDN_ENDPOINT: {
    USECASE: 'CDN_ENDPOINT',
    URL: 'https://qggjb3bac7ua6pzr5sqpbujpze0jmlfg.lambda-url.us-east-2.on.aws',
  },
  SIGNIN: {
    USECASE: 'SIGNIN',
    URL: '/auth/login',
  },
  FORGOT_PASSWORD: {
    USECASE: 'FORGOT_PASSWORD',
    URL: '/auth/forgot-password',
  },
  RESET_PASSWORD: {
    USECASE: 'RESET_PASSWORD',
    URL: '/auth/reset-password/$token',
  },
}

export default UrlConstants
