import React from 'react'
import SidebarLayout from '../../components/common/Layout/SidebarLayout'
import RouteConstants from '../../constants/RouteConstants'
import { BiUser } from 'react-icons/bi'
import { LocalPharmacy, PinDrop, Settings } from '@mui/icons-material'
import { HiHeart, HiOutlineDocumentReport } from 'react-icons/hi'
import { RiNurseFill } from 'react-icons/ri'

const MeRoutes = [
  {
    id: 1,
    title: 'Profile',
    route: RouteConstants.PROFILE_ME,
    icon: <BiUser style={{ fontSize: '18px' }} />,
  },
  {
    id: 2,
    title: 'Contact & Addresses',
    route: RouteConstants.PROFILE_ME_CONTACT,
    icon: <PinDrop style={{ fontSize: '18px' }} />,
  },
  {
    id: 3,
    title: 'Health',
    route: RouteConstants.PROFILE_ME_HEALTH,
    icon: <HiHeart style={{ fontSize: '18px' }} />,
  },
  {
    id: 4,
    title: 'Prescriptions',
    route: RouteConstants.PROFILE_ME_PRESCRIPTION,
    icon: <HiOutlineDocumentReport style={{ fontSize: '18px' }} />,
  },
  {
    id: 5,
    title: 'Doctors',
    route: RouteConstants.PROFILE_ME_DOCTOR,
    icon: <RiNurseFill style={{ fontSize: '18px' }} />,
  },
  {
    id: 6,
    title: 'Pharmacy',
    route: RouteConstants.PROFILE_ME_PHARMACY,
    icon: <LocalPharmacy style={{ fontSize: '18px' }} />,
  },
  {
    id: 7,
    title: 'Preferences',
    route: RouteConstants.PROFILE_ME_PREFERENCES,
    icon: <Settings style={{ fontSize: '18px' }} />,
  },
]

const Me = () => {
  return <SidebarLayout sidebarRoutes={MeRoutes} />
}

export default Me
