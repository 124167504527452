import EmailSent from './EmailSent'
import React from 'react'
import ValidationUtils from '../../utils/validation/ValidationUtils'
import eipFlag from '../../assets/eipFlag.png'
import {
  CardWrapper,
  Footer,
  Form,
  LogoWrapper,
  MainContainer,
  Wrapper,
} from '../Login/LoginStyled'
import Paper from '../../components/common/Paper/Paper'
import InputField from '../../components/common/Input/InputField'
import Button from '../../components/common/Button/Button'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import styled from '@emotion/styled'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { useWindowWidth } from '../../utils/hooks'
import { useNavigate } from 'react-router-dom'
import StringConstants from '../../constants/StringConstants'
import { isEmpty } from 'lodash'
import ApiManager from '../../api/ApiManager'
import UrlConstants from '../../constants/UrlConstants'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import { useSnackbar } from 'notistack'

const ButtonWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0px;
`

const ForgotPassword = () => {
  const isMobile = useWindowWidth()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [state, setState] = React.useState({
    isLoading: false,
    isEmailSent: false,
    email: '',
    emailError: false,
  })

  const isValidEmail = (email: string) => {
    return ValidationUtils.validateEmail(email)
  }

  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let email = isEmpty(e.target.value) ? '' : e.target.value
    setState({
      ...state,
      email,
      emailError: !isValidEmail(email),
    })
  }

  const handleForgotPassword = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    let emailError = !isValidEmail(state.email)
    if (emailError) {
      setState({
        ...state,
        emailError,
      })
    } else {
      setState({
        ...state,
        emailError: false,
        isLoading: true,
      })

      try {
        const response = await ApiManager.makeApiCall(
          UrlConstants.FORGOT_PASSWORD.USECASE,
          HttpMethods.POST_METHOD,
          { email: state.email }
        ).catch((error) => {
          throw error
        })

        if (response.status === 200) {
          setState((state) => {
            return {
              ...state,
              isEmailSent: true,
            }
          })
        } else throw response
      } catch (error) {
        enqueueSnackbar(StringConstants.INTERNAL_SERVER_ERROR, {
          variant: 'error',
        })
      } finally {
        setState((state) => {
          return {
            ...state,
            isLoading: false,
          }
        })
      }
    }
  }

  return (
    <MainContainer>
      <Wrapper>
        <LogoWrapper>
          <img src={eipFlag} alt='eip flag' />
        </LogoWrapper>
        <Paper
          type='card'
          margin='20px 0px'
          padding='20px'
          boxShadow='0px 0px 12px -2px rgba(0, 0, 0, 0.05)'
        >
          <CardWrapper>
            {!state.isEmailSent ? (
              <>
                <h2>Reset your password</h2>
                <Form>
                  <InputField
                    type='email'
                    margin='20px 0px 0px 0px'
                    placeholder='Your email'
                    superScript='Enter your email'
                    onChange={handleEmailInput}
                    value={state.email || ''}
                    symbol={<MailOutlineIcon />}
                    fullWidth
                    error={state.emailError}
                    helperText={
                      state.emailError
                        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_EMAIL
                        : ''
                    }
                  />
                  <ButtonWrapper isMobile={isMobile}>
                    <Button
                      startIcon={<AiOutlineLeft />}
                      width={isMobile ? '100%' : '150px'}
                      variant='outlined'
                      onClick={() => {
                        navigate(-1)
                      }}
                      disabled={state.isLoading}
                    >
                      Back
                    </Button>
                    <Button
                      endIcon={<AiOutlineRight />}
                      width={isMobile ? '100%' : '150px'}
                      onClick={handleForgotPassword}
                      loading={state.isLoading}
                      disabled={state.isLoading}
                    >
                      Send
                    </Button>
                  </ButtonWrapper>
                </Form>
              </>
            ) : (
              <EmailSent />
            )}
          </CardWrapper>
        </Paper>
        <Footer>
          <span>Privacy Policy</span>
          <span>Terms of Service</span>
        </Footer>
      </Wrapper>
    </MainContainer>
  )
}

export default ForgotPassword
