import styled from '@emotion/styled'
import React from 'react'
import Heading from '../../../components/Profile/Heading'
import Section from '../../../components/Profile/Section'
import theme from '../../../theme/theme'
import PrescriptionSearchField from '../../../components/Profile/PrescriptionSearchField'
import SavedPrescriptionCard from '../../../components/Profile/SavedPrescriptionCard'

const MainContainer = styled.div`
  padding-bottom: 50px;
`
const SectionHeading = styled.h2`
  color: ${theme.colors.primary};
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 20px;
`
const SectionsWrapper = styled.div``

const PrescriptionSection = () => {
  return (
    <MainContainer>
      <Heading>Prescriptions</Heading>
      <SectionsWrapper>
        <Section label=''>
          <SectionHeading>Add your prescription drugs</SectionHeading>
          <PrescriptionSearchField
            options={['dologesic', 'dolomite', 'dolophine']}
          />
        </Section>
        <Section label=''>
          <SectionHeading>Added Drugs</SectionHeading>
          <SavedPrescriptionCard />
          <SavedPrescriptionCard />
          <SavedPrescriptionCard />
        </Section>
      </SectionsWrapper>
    </MainContainer>
  )
}

export default PrescriptionSection
