import styled from '@emotion/styled'
import React from 'react'
import { Routes, useLocation } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import TopTabGroup from '../TopTabGroup/TopTabGroup'
import theme from '../../../theme/theme'
import { isAuthenticatedUser } from '../../../utils/AuthUtils'
import FloatingHelpButtons from '../../Help/FloatingHelpButtons'

interface LayoutProps {
  children?: React.ReactNode | JSX.Element | string | undefined
}

const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: ${theme.colors.lightBack3};
`

const Wrapper = styled.div`
  padding: 0px 80px;
  @media screen and (max-width: 800px) {
    padding: 0px 20px;
  }
`

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation()
  return (
    <>
      {!isAuthenticatedUser() ? (
        <Routes>{children}</Routes>
      ) : (
        <MainContainer>
          <div>
            <Navbar />
            <TopTabGroup
              showBackButton={location.pathname.includes('profile')}
              navFor={
                location.pathname.includes('profile') ? 'profile' : 'default'
              }
            />
            <Wrapper>
              <Routes>{children}</Routes>
            </Wrapper>
          </div>
          <Footer />
          <FloatingHelpButtons />
        </MainContainer>
      )}
    </>
  )
}

export default Layout
