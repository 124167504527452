import styled from '@emotion/styled'
import React from 'react'
import Heading from '../../../components/Profile/Heading'
import Section from '../../../components/Profile/Section'
import InputField from '../../../components/common/Input/InputField'
import { useWindowWidth } from '../../../utils/hooks'
import Button from '../../../components/common/Button/Button'
import DatePicker from '../../../components/common/Input/DatePicker'
import RadioGroup from '../../../components/common/RadioGroup/RadioGroup'
import Select from '../../../components/common/Select/Select'
import theme from '../../../theme/theme'
import { SelectChangeEvent } from '@mui/material'
import {
  monthsMappedToObject,
  yearsMappedToObject,
} from '../../../constants/DateConstants'

const MainContainer = styled.div`
  padding-bottom: 50px;
`
const QuestionLabel = styled.div`
  font-size: 14px;
  color: ${theme.colors.textDark};
  font-weight: 600;
  padding-bottom: 10px;
  span {
    font-weight: 700;
  }
`

const ProfileSection = () => {
  const isMobile = useWindowWidth()
  const [medicarePartBEffectiveDate, setMedicarePartBEffectiveDate] =
    React.useState({
      month: '',
      year: '',
    })
  return (
    <MainContainer>
      <Heading>Profile</Heading>
      <Section label='' divider>
        <div style={{ display: 'flex', gridGap: '0px 20px', flexWrap: 'wrap' }}>
          <InputField
            superScript='First Name'
            placeholder='Your first name'
            type='text'
            fullWidth={isMobile}
          />
          <InputField
            superScript='Last Name'
            placeholder='Your last name'
            type='text'
            fullWidth={isMobile}
          />
        </div>
        <Button fontSize='14px' fullWidth={isMobile}>
          Change my Personal Information
        </Button>
      </Section>
      <Section label='' divider>
        <DatePicker superScript='Date of Birth' fullWidth={isMobile} />
        <Button fullWidth={isMobile}>Request DOB change</Button>
      </Section>
      <Section label=''>
        <RadioGroup
          groupMargin='20px 0px'
          groupLabel='Currently enrolled in Medicare Part B?'
          row={!isMobile}
          value=''
          onChange={(e) => console.log(e.target.value)}
          radioButtons={[
            {
              label: 'Yes',
              value: 'yes',
            },
            {
              label: 'No',
              value: 'no',
            },
          ]}
        />

        <QuestionLabel>
          <span>Medicare Part B</span> effective date?
        </QuestionLabel>
        <div
          style={{
            display: 'flex',
            gridGap: '10px',
            flexWrap: 'wrap',
            paddingBottom: '20px',
          }}
        >
          <Select
            width='140px'
            placeholder='Month'
            value={medicarePartBEffectiveDate.month}
            options={monthsMappedToObject}
            onChange={(e: SelectChangeEvent) =>
              setMedicarePartBEffectiveDate((medicarePartBEffectiveDate) => {
                return {
                  month: e.target.value,
                  year: medicarePartBEffectiveDate.year,
                }
              })
            }
          />
          <Select
            width='140px'
            placeholder='Year'
            value={medicarePartBEffectiveDate.year}
            options={yearsMappedToObject}
            onChange={(e: SelectChangeEvent) =>
              setMedicarePartBEffectiveDate((medicarePartBEffectiveDate) => {
                return {
                  month: medicarePartBEffectiveDate.month,
                  year: e.target.value,
                }
              })
            }
          />
        </div>
        <Button fontSize='14px' fullWidth={isMobile}>
          Request Part B Information change
        </Button>
      </Section>
    </MainContainer>
  )
}

export default ProfileSection
