import { Navigate, Route } from 'react-router-dom'

import ContactSection from './pages/Profile/SubPages/ContactSection'
import DoctorsSection from './pages/Profile/SubPages/DoctorsSection'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import HealthSection from './pages/Profile/SubPages/HealthSection'
import Help from './pages/Help/Help'
import Layout from './components/common/Layout/Layout'
import Login from './pages/Login/Login'
import Me from './pages/Profile/Me'
import MyCases from './pages/Help/MyCases'
import MyPlans from './pages/MyPlans/MyPlans'
import MyReferrals from './pages/Referral/MyReferrals'
import Notifications from './pages/Notifications/Notifications'
import PharmacySection from './pages/Profile/SubPages/PharmacySection'
import PreferencesSection from './pages/Profile/SubPages/PreferencesSection'
import PrescriptionSection from './pages/Profile/SubPages/PrescriptionSection'
import ProfileSection from './pages/Profile/SubPages/ProfileSection'
import ReachOut from './pages/Help/ReachOut'
import React from 'react'
import ReferSomeone from './pages/Referral/ReferSomeone'
import Referral from './pages/Referral/Referral'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import ResourceCenter from './pages/ResourceCenter/ResourceCenter'
import RouteConstants from './constants/RouteConstants'
import Signup from './pages/Login/Signup'
import Spouse from './pages/Profile/Spouse'
import { isAuthenticatedUser } from './utils/AuthUtils'
import { useScrollToTop } from './utils/hooks'

const App = () => {
  useScrollToTop()
  return (
    <Layout>
      {/* <Route
        path={RouteConstants.DEV_DOCUMENTATION}
        element={<Documentation />}
      /> */}
      {isAuthenticatedUser() ? (
        <>
          <Route path={RouteConstants.MY_PLANS} element={<MyPlans />} />
          <Route path={RouteConstants.HELP} element={<Help />}>
            <Route index element={<ReachOut />} />
            <Route path={RouteConstants.MY_CASES} element={<MyCases />} />
          </Route>
          <Route path={RouteConstants.REFERRAL} element={<Referral />}>
            <Route index element={<ReferSomeone />} />
            <Route
              path={RouteConstants.MY_REFERRALS}
              element={<MyReferrals />}
            />
          </Route>
          <Route
            path={RouteConstants.RESOURCE_CENTER}
            element={<ResourceCenter />}
          />
          <Route
            path={RouteConstants.PROFILE}
            element={<Navigate to={RouteConstants.PROFILE_ME} />}
          />
          <Route path={RouteConstants.PROFILE_ME} element={<Me />}>
            <Route index element={<ProfileSection />} />
            <Route
              path={RouteConstants.PROFILE_ME_CONTACT}
              element={<ContactSection />}
            />
            <Route
              path={RouteConstants.PROFILE_ME_HEALTH}
              element={<HealthSection />}
            />
            <Route
              path={RouteConstants.PROFILE_ME_PRESCRIPTION}
              element={<PrescriptionSection />}
            />
            <Route
              path={RouteConstants.PROFILE_ME_DOCTOR}
              element={<DoctorsSection />}
            />
            <Route
              path={RouteConstants.PROFILE_ME_PHARMACY}
              element={<PharmacySection />}
            />
            <Route
              path={RouteConstants.PROFILE_ME_PREFERENCES}
              element={<PreferencesSection />}
            />
          </Route>
          <Route path={RouteConstants.PROFILE_SPOUSE} element={<Spouse />}>
            <Route index element={<ProfileSection />} />
            <Route
              path={RouteConstants.PROFILE_SPOUSE_HEALTH}
              element={<HealthSection />}
            />
            <Route
              path={RouteConstants.PROFILE_SPOUSE_PRESCRIPTION}
              element={<PrescriptionSection />}
            />
            <Route
              path={RouteConstants.PROFILE_SPOUSE_DOCTOR}
              element={<DoctorsSection />}
            />
            <Route
              path={RouteConstants.PROFILE_SPOUSE_PHARMACY}
              element={<PharmacySection />}
            />
          </Route>
          <Route
            path={RouteConstants.NOTIFICATIONS}
            element={<Notifications />}
          />
          <Route path='*' element={<Navigate to={RouteConstants.MY_PLANS} />} />
        </>
      ) : (
        <>
          <Route
            path={RouteConstants.HOME}
            element={<Navigate replace to={RouteConstants.LOGIN} />}
          />
          <Route path={RouteConstants.LOGIN} element={<Login />} />
          <Route
            path={RouteConstants.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route
            path={RouteConstants.RESET_PASSWORD}
            element={<ResetPassword />}
          />
          <Route path={RouteConstants.SIGNUP} element={<Signup />} />
          <Route path='*' element={<Navigate to={RouteConstants.LOGIN} />} />
        </>
      )}
    </Layout>
  )
}

export default App
