import styled from '@emotion/styled'
import React from 'react'
import Heading from '../../../components/Profile/Heading'
import Section from '../../../components/Profile/Section'
import Button from '../../../components/common/Button/Button'
import RadioGroup from '../../../components/common/RadioGroup/RadioGroup'

const MainContainer = styled.div`
  padding-bottom: 50px;
`

const PreferencesSection = () => {
  return (
    <MainContainer>
      <Heading>Preferences</Heading>
      <Section label=''>
        <RadioGroup
          groupMargin='20px 0px'
          groupLabel='Subscribe to our newsletter'
          value=''
          onChange={(e) => console.log(e.target.value)}
          radioButtons={[
            {
              label: 'Yes',
              value: 'yes',
            },
            {
              label: 'No',
              value: 'no',
            },
          ]}
        />
        <RadioGroup
          groupMargin='20px 0px'
          groupLabel='Send me alerts about my Account, Appointments or Plan via SMS'
          value=''
          onChange={(e) => console.log(e.target.value)}
          radioButtons={[
            {
              label: 'Yes',
              value: 'yes',
            },
            {
              label: 'No',
              value: 'no',
            },
          ]}
        />
      </Section>

      <Button margin='20px 0px 0px 0px' fontSize='14px'>
        Save Changes
      </Button>
    </MainContainer>
  )
}

export default PreferencesSection
