import styled from '@emotion/styled'
import React from 'react'
import Heading from '../../../components/Profile/Heading'
import Section from '../../../components/Profile/Section'
import { useWindowWidth } from '../../../utils/hooks'
import RadioGroup from '../../../components/common/RadioGroup/RadioGroup'
import theme from '../../../theme/theme'
import ChangeZipCodeModal from '../../../components/Profile/ChangeZipCodeModal'
import SavedPharmacyCard from '../../../components/Profile/SavedPharmacyCard'
import Button from '../../../components/common/Button/Button'
import PharmacySearchField, {
  PharmacySearchOptionType,
} from '../../../components/Profile/PharmacySearchField'

const MainContainer = styled.div`
  padding-bottom: 50px;
`
const SectionHeading = styled.h2`
  color: ${theme.colors.primary};
  font-size: 18px;
  font-weight: 700;
`
const SectionParagraph = styled.div`
  font-size: 14px;
  color: ${theme.colors.textDark};
  max-width: 500px;
  padding: 20px 0px;
`
const ZipCodeWrapper = styled.div`
  padding: 20px 0px;
  h3 {
    font-size: 14px;
    color: ${theme.colors.textDark};
    font-weight: 600;
  }
  p {
    font-size: 14px;
    color: ${theme.colors.textDark};
    display: flex;
    span {
      color: ${theme.colors.primary};
      font-weight: 700;
      margin-left: 10px;
      cursor: pointer;
      text-decoration: underline;
      font-style: italic;
      &:hover {
        color: ${theme.colors.secondary};
      }
    }
  }
`
const SearchFieldWrapper = styled.div``

const PharmacySection = () => {
  type pharmacyStateType = 'mail' | 'retail' | ''
  const isMobile = useWindowWidth()
  const [showZipInput, setShowZipInput] = React.useState(false)
  const [showSavedPharmacy, setShowSavedPharmacy] = React.useState(true)
  const [pharmacyType, setPharmacyType] = React.useState<pharmacyStateType>('')
  const [pharmacySearchOptionList, setPharmacySearchOptionList] =
    React.useState([
      {
        id: 1,
        pharmacyName: 'GIANT OF MARYLAND LLC',
        pharmacyAddress: '1400 7TH ST N.W., WASHINGTON, DC, 00002',
        range: '0.19mi',
      },
      {
        id: 2,
        pharmacyName: 'GIANT OF DISNEY LLC',
        pharmacyAddress: '140GTON, DC, 00002',
        range: '0.2mi',
      },
      {
        id: 3,
        pharmacyName: 'GIANT OF RANCHILAND LLC',
        pharmacyAddress: '1400 N, DC, 00002',
        range: '0.5mi',
      },
      {
        id: 4,
        pharmacyName: 'GIANT OF HYDERABAD LAND LLC',
        pharmacyAddress: '1400 7TH ST N.W., WASHINGTON, DC, 00002',
        range: '5mi',
      },
    ] as PharmacySearchOptionType[])
  return (
    <MainContainer>
      <Heading>{showSavedPharmacy ? 'Saved Pharmacies' : 'Pharmacy'}</Heading>
      {showSavedPharmacy ? (
        <Section label=''>
          <SavedPharmacyCard />
          <SavedPharmacyCard
            pharmacyAddress='50 W JEFFERSON ST, PHOENIX, AZ, 85003'
            pharmacyName='CVS PHARMACY'
            range='0.46 mi'
          />
          <Button
            fontSize='14px'
            fullWidth={isMobile}
            margin='20px 0px 0px 0px'
            onClick={() => {
              setShowSavedPharmacy((showSavedPharmacy) => !showSavedPharmacy)
            }}
          >
            Add another pharmacy
          </Button>
        </Section>
      ) : (
        <Section label=''>
          <SectionHeading>Choose your Pharmacy</SectionHeading>
          <SectionParagraph>
            Different pharmacies have different drug prices. Selecting your
            pharmacy can help us determine your estimated drug cost for each
            plan.
          </SectionParagraph>
          <RadioGroup
            fontSize='14px'
            padding=' 5px 10px'
            groupLabel='Select your pharmacy type'
            row={!isMobile}
            radioButtons={[
              {
                value: 'retail',
                label: 'Retail',
              },
              {
                value: 'mail',
                label: 'Mail Order',
              },
            ]}
            onChange={(e) => {
              setPharmacyType(() => e.target.value as pharmacyStateType)
            }}
            value=''
          />
          {pharmacyType === 'retail' && (
            <>
              <ZipCodeWrapper>
                <h3>Search for your pharmacy near</h3>
                <p>
                  11234 - Brooklyn, New York{' '}
                  <span
                    onClick={() => {
                      setShowZipInput((showZipInput) => !showZipInput)
                    }}
                  >
                    change
                  </span>
                </p>
                <ChangeZipCodeModal
                  open={showZipInput}
                  handleClose={() => {
                    setShowZipInput((showZipInput) => !showZipInput)
                  }}
                />
              </ZipCodeWrapper>
              <SearchFieldWrapper>
                <PharmacySearchField
                  options={pharmacySearchOptionList}
                  placeholder='Start typing your pharamcy name...'
                />
              </SearchFieldWrapper>
            </>
          )}
        </Section>
      )}
    </MainContainer>
  )
}

export default PharmacySection
