import styled from '@emotion/styled'
import React from 'react'
import theme from '../../theme/theme'
import MyReferralCard from '../../components/Referral/MyReferralCard'

const MainWrapper = styled.div`
  max-width: 800px;
`

const Header = styled.h2`
  font-size: 18px;
  color: ${theme.colors.textDark};
  font-weight: 700;
  padding-bottom: 20px;
`

const ContentWrapper = styled.div`
  padding-bottom: 50px;
`

const MyReferrals = () => {
  return (
    <MainWrapper>
      <ContentWrapper>
        <Header>Ongoing</Header>
        <MyReferralCard />
      </ContentWrapper>
      <ContentWrapper>
        <Header>Completed</Header>
        <MyReferralCard />
        <MyReferralCard />
        <MyReferralCard />
      </ContentWrapper>
    </MainWrapper>
  )
}

export default MyReferrals
