import React from 'react'
import Modal from './Modal'
import styled from '@emotion/styled'
import theme from '../../../theme/theme'
import RadioGroup from '../RadioGroup/RadioGroup'
import TextArea from '../Input/TextArea'
import Button from '../Button/Button'
import { BsPatchCheckFill } from 'react-icons/bs'
import Paper from '../Paper/Paper'
import { HiOutlineTicket } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import RouteConstants from '../../../constants/RouteConstants'

interface EnquiryModalProps {
  open: boolean
  handleClose: () => void
  heading: string
}

const Wrapper = styled.div``

const QuestionWrapper = styled.div`
  padding-bottom: 20px;
`
const SectionWrapper = styled.div`
  padding-bottom: 20px;
  display: flex;
  column-gap: 10px;

  p {
    font-size: 14px;
    color: ${theme.colors.textDark};
    span {
      font-weight: 700;
    }
    .link {
      color: ${theme.colors.primary};
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: ${theme.colors.secondary};
      }
    }
  }
`

const Question = styled.p`
  padding: 10px 0px;
  font-weight: 600;
  color: ${theme.colors.textDark};
`

const RadioLabel = styled.p`
  font-size: 14px;
  color: ${theme.colors.textDark};
  span {
    font-weight: 700;
  }
`

const Ticket = styled.div`
  font-size: 14px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 600;
  color: ${theme.colors.textDark};
  .ticketIcon {
    font-size: 18px;
    color: ${theme.colors.secondary};
  }
`

const EnquiryModal: React.FC<EnquiryModalProps> = ({
  open,
  handleClose,
  heading,
}) => {
  const navigate = useNavigate()
  const [enquirySubmitted, setEnquirySubmitted] = React.useState(false)
  const handleEnquirySubmission = () => {
    setEnquirySubmitted(true)
  }
  return (
    <Modal
      open={open}
      handleClose={() => {
        setEnquirySubmitted(() => false)
        handleClose()
      }}
      heading={heading}
      width={350}
    >
      {!enquirySubmitted ? (
        <Wrapper>
          <QuestionWrapper>
            <Question>
              Which number should our Customer Support Agent call you on?
            </Question>
            <RadioGroup
              radioButtons={[
                {
                  value: '(914) 854-6286',
                  label: <RadioLabel>(914) 854-6286</RadioLabel>,
                },
                {
                  value: '(888) 983-4183',
                  label: <RadioLabel>(888) 983-4183</RadioLabel>,
                },
                {
                  value: 'other',
                  label: <RadioLabel>Another Number</RadioLabel>,
                },
              ]}
              onChange={(e) => {
                console.log(e.target.value)
              }}
              value='(914) 854-6286'
            />
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>At what time should we reach out to you?</Question>
            <RadioGroup
              radioButtons={[
                {
                  value: 'morning',
                  label: (
                    <RadioLabel>
                      Morning <span>(9:00 AM to 12:00 PM EDT)</span>
                    </RadioLabel>
                  ),
                },
                {
                  value: 'evening',
                  label: (
                    <RadioLabel>
                      Evening <span>(2:00 PM to 5:00 PM EDT)</span>
                    </RadioLabel>
                  ),
                },
              ]}
              onChange={(e) => {
                console.log(e.target.value)
              }}
              value='morning'
            />
          </QuestionWrapper>
          <QuestionWrapper>
            <TextArea
              onChange={(e) => {
                console.log(e)
              }}
              placeholder='Please leave a brief description of how we can help you'
            />
          </QuestionWrapper>
          <Button fullWidth onClick={handleEnquirySubmission}>
            Submit
          </Button>
        </Wrapper>
      ) : (
        <Wrapper>
          <SectionWrapper>
            <BsPatchCheckFill
              style={{ color: theme.colors.success, fontSize: '24px' }}
            />
            <p>Hi Michael, Thank You for your inquiry about {heading}</p>
          </SectionWrapper>
          <SectionWrapper>
            <p>
              A Customer Support Agent will reach out to you in 24 to 48 hours.
            </p>
          </SectionWrapper>
          <SectionWrapper>
            <p>
              A new case <span>{heading}</span> has been created. You can track
              the progress on{' '}
              <span
                className='link'
                onClick={() => {
                  navigate(RouteConstants.MY_CASES)
                }}
              >
                My Cases
              </span>{' '}
              page.
            </p>
          </SectionWrapper>

          <Paper>
            <Ticket className='ticket'>
              <HiOutlineTicket className='ticketIcon' />
              <p>Ticket #34567</p>
            </Ticket>
          </Paper>
          <Button
            fullWidth
            onClick={() => {
              setEnquirySubmitted(() => false)
              handleClose()
            }}
            margin='40px 0px 0px 0px'
          >
            Close
          </Button>
        </Wrapper>
      )}
    </Modal>
  )
}

export default EnquiryModal
