import styled from '@emotion/styled'
import React from 'react'
import theme from '../../../theme/theme'

const Container = styled.div<{
  showFooter?: boolean
}>`
  display: ${(props) => (props.showFooter ? 'flex' : 'none')};
  justify-content: center;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.textLight};
  font-size: 12px;
  padding: 20px 80px;
  @media screen and (max-width: 800px) {
    padding: 20px 20px;
  }
  text-align: center;
  p {
    padding: 10px 0px;

    a {
      color: inherit;
    }
  }
`

const Footer: React.FC<{ showFooter?: boolean }> = ({ showFooter = true }) => {
  return (
    <Container showFooter={showFooter}>
      <footer style={{ maxWidth: '800px' }}>
        <p>
          ©{' '}
          <a
            target='_blank'
            rel='noreferrer'
            href='https://eliteinsurancepartners.com/'
          >
            {new Date().getFullYear()} Elite Insurance Partners
          </a>
          . All Rights Reserved. Phone:&nbsp;
          <a href='tel:+18008452484'>(800) 845-2484</a> | Email:&nbsp;
          <a href='mailto: info@eliteinsurancepartners.com'>
            info@eliteinsurancepartners.com
          </a>
        </p>
        <div>
          Owned by: Elite Insurance Partners LLC. This website is not connected
          with the federal government or the federal Medicare program. We do not
          offer every plan available in your area. Any information we provide is
          limited to those plans we do offer in your area. Please contact
          Medicare.gov or 1-800-MEDICARE to get information on all of your
          options.&nbsp;
          <p>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://eliteinsurancepartners.com/privacy-policy/'
            >
              Privacy Policy&nbsp;
            </a>
            &nbsp;and&nbsp;
            <a
              target='_blank'
              rel='noreferrer'
              href='https://eliteinsurancepartners.com/terms-and-conditions/'
            >
              Terms of Use.
            </a>
          </p>
        </div>
      </footer>
    </Container>
  )
}

export default Footer
