import React from 'react'
import { SelectChangeEvent, Select as MuiSelect, MenuItem } from '@mui/material'
import theme from '../../../theme/theme'
import styled from '@emotion/styled'

interface SelectProps {
  placeholder?: string
  label?: string
  width?: string
  fullWidth?: boolean
  error?: boolean
  value: string | undefined
  helperText?: string
  onChange: (e: SelectChangeEvent) => void
  options: {
    label: string | number
    value: string | number
  }[]
}

const Placeholder = styled.span`
  color: ${theme.colors.textGrey};
  font-size: 14px;
`

const ErrorLabel = styled.p`
  font-size: 12px;
  margin-left: 14px;
  color: ${theme.colors.error};
  padding-top: 5px;
`

const Label = styled.p`
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-left: 14px;
  color: ${theme.colors.textDark};
  padding-bottom: 8px;
`

const Select: React.FC<SelectProps> = ({
  placeholder = 'Select Your Option',
  label,
  width = '265px',
  fullWidth = false,
  error = false,
  value = '',
  helperText,
  onChange,
  options,
}) => {
  return (
    <div>
      {label && <Label>{label}</Label>}
      <MuiSelect
        onChange={onChange}
        value={value}
        error={error}
        displayEmpty
        margin='dense'
        renderValue={
          value !== ''
            ? undefined
            : () => <Placeholder>{placeholder}</Placeholder>
        }
        sx={{
          width: fullWidth ? '100%' : width,
          borderRadius: '36px',
          height: '42px',
          '.MuiSelect-outlined': {
            padding: '8px 20px',
          },
        }}
      >
        <MenuItem value='' disabled>
          Select your option
        </MenuItem>
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <ErrorLabel>{helperText}</ErrorLabel>}
    </div>
  )
}

export default Select
