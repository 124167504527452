import theme from './theme'
import { createTheme } from '@mui/material/styles'

export default createTheme({
  palette: {
    primary: {
      main: theme.colors.primary,
    },
    secondary: {
      main: theme.colors.secondary,
    },
    warning: {
      main: theme.colors.warning,
    },
    success: {
      main: theme.colors.success,
    },
  },
})
