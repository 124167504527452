import React from 'react'
import SidebarLayout from '../../components/common/Layout/SidebarLayout'
import RouteConstants from '../../constants/RouteConstants'
import { AiOutlineHeart } from 'react-icons/ai'
import { BiMessageDetail } from 'react-icons/bi'

const HelpRoutes = [
  {
    id: 1,
    title: 'Reach Out',
    route: RouteConstants.HELP,
    icon: <AiOutlineHeart style={{ fontSize: '18px' }} />,
  },
  {
    id: 2,
    title: 'My Cases',
    route: RouteConstants.MY_CASES,
    icon: <BiMessageDetail style={{ fontSize: '18px' }} />,
  },
]

const Help = () => {
  return <SidebarLayout sidebarRoutes={HelpRoutes} />
}

export default Help
