import styled from '@emotion/styled'
import React from 'react'
import CallAgent from './CallAgent'
import theme from '../../../theme/theme'
import { AiOutlineBell } from 'react-icons/ai'
import DropdownGroup from '../DropdownGroup/DropdownGroup'
import UserDropdown from './UserDropdown'
import NotificationDropdown from './NotificationDropdown'
import { useWindowWidth } from '../../../utils/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { deleteToken } from '../../../utils/AuthUtils'
import RouteConstants from '../../../constants/RouteConstants'

const MainWrapper = styled.div`
  display: flex;
  grid-gap: 20px;
  align-items: center;

  @media screen and (max-width: 800px) {
    flex-direction: row-reverse;
  }
`
const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 20px;
`

const DividerLine = styled.div`
  height: 30px;
  width: 1px;
  background-color: ${theme.colors.borderColor};
  @media screen and (max-width: 500px) {
    display: none;
  }
`

const NotificationWrapper = styled.div`
  position: relative;
  background-color: ${theme.colors.lightBack2};
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .AiOutlineBell {
    font-size: 24px;
    color: ${theme.colors.primary};
  }
  &:hover {
    background-color: ${theme.colors.primary};
    .AiOutlineBell {
      color: ${theme.colors.white};
    }
  }

  @media screen and (max-width: 500px) {
    padding: 6px;
    width: 30px;
    height: 30px;
    font-size: 14px;
    .AiOutlineBell {
      font-size: 18px;
    }
  }
`

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 5px;
`

const Initial = styled.div`
  color: ${theme.colors.white};
  background-color: ${theme.colors.primary};
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 500px) {
    padding: 6px;
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
`

const Name = styled.div`
  font-size: 18px;
  color: ${theme.colors.primary};
  font-weight: 600;
  @media screen and (max-width: 800px) {
    display: none;
  }
`

const NotificationCount = styled.span`
  position: absolute;
  font-size: 12px;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.textLight};
  border-radius: 50% 50%;
  background-color: ${theme.colors.secondary};
`

const Navlinks = () => {
  const isMobile = useWindowWidth()
  const navigate = useNavigate()
  const location = useLocation()

  const logout = () => {
    deleteToken()
    localStorage.clear()
    sessionStorage.clear()
    navigate(0)
  }
  return (
    <MainWrapper>
      <NavWrapper>
        {!isMobile ? (
          <DropdownGroup
            variant='nav'
            padding='10px 20px'
            dropdownLabel={
              <NotificationWrapper>
                <AiOutlineBell className='AiOutlineBell' />
                <NotificationCount>5</NotificationCount>
              </NotificationWrapper>
            }
          >
            <NotificationDropdown />
          </DropdownGroup>
        ) : (
          <NotificationWrapper>
            <p
              onClick={() => {
                location.pathname === RouteConstants.NOTIFICATIONS
                  ? navigate(-1)
                  : navigate(RouteConstants.NOTIFICATIONS)
              }}
            >
              <AiOutlineBell className='AiOutlineBell' />
              <NotificationCount>5</NotificationCount>
            </p>
          </NotificationWrapper>
        )}
        <DropdownGroup
          variant='nav'
          arrow
          padding='20px 10px'
          dropdownLabel={
            <UserWrapper>
              <Initial>M</Initial>
              <Name>Michael Scott</Name>
            </UserWrapper>
          }
        >
          <UserDropdown logout={logout} />
        </DropdownGroup>
      </NavWrapper>
      <DividerLine />
      <CallAgent />
    </MainWrapper>
  )
}

export default Navlinks
