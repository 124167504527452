import styled from '@emotion/styled'
import React from 'react'
import theme from '../../../theme/theme'
import { IoCallOutline } from 'react-icons/io5'
import StringConstants from '../../../constants/StringConstants'

const MainWrapper = styled.div``

const TopSection = styled.p`
  color: ${theme.colors.primary};
  font-size: 14px;
  font-weight: 600;

  @media screen and (max-width: 800px) {
    display: none;
  }
`
const BottomSection = styled.p`
  color: ${theme.colors.primary};
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  grid-gap: 10px;

  .IoCallOutline {
    font-size: 24px;
  }

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
    grid-gap: 2px;
    .IoCallOutline {
      font-size: 16px;
    }
  }
`

const CallAgent = () => {
  return (
    <MainWrapper>
      <TopSection>Have questions? Need help?</TopSection>
      <BottomSection>
        <IoCallOutline className='IoCallOutline' />
        {StringConstants.DEFAULT_PHONE_NUMBER}
      </BottomSection>
    </MainWrapper>
  )
}

export default CallAgent
