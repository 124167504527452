import React from 'react'
import {
  CardWrapper,
  Form,
  LogoWrapper,
  MainContainer,
  Wrapper,
} from '../Login/LoginStyled'
import eipFlag from '../../assets/eipFlag.png'
import Paper from '../../components/common/Paper/Paper'
import PasswordField from '../../components/common/Input/PasswordField'
import StringConstants from '../../constants/StringConstants'
import ValidationUtils from '../../utils/validation/ValidationUtils'
import Button from '../../components/common/Button/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import ApiManager from '../../api/ApiManager'
import UrlConstants from '../../constants/UrlConstants'
import { HttpMethods } from '../../enums/HttpMethodsEnum'
import { StatusCode } from '../../enums/statusCode.enum'
import RouteConstants from '../../constants/RouteConstants'
import { AiOutlineRight } from 'react-icons/ai'
import { BsCheckCircleFill } from 'react-icons/bs'
import styled from '@emotion/styled'
import theme from '../../theme/theme'
import { useSnackbar } from 'notistack'

const SuccessContent = styled.div`
  text-align: left;
  padding: 20px 30px;
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: ${theme.colors.textDark};
  }
  p {
    color: ${theme.colors.textDark};
    font-size: 14px;
  }
`
const TickIcon = styled(BsCheckCircleFill)`
  color: ${theme.colors.success};
  font-size: 15px;
  margin-left: 10px;
  @media screen and (max-width: 500px) {
    margin-left: 0px;
  }
`

const ResetPassword = () => {
  const [state, setState] = React.useState({
    isLoading: false,
    isResetSuccess: false,
    password: '',
    confirmPassword: '',
    passwordError: false,
    confirmPasswordError: false,
  })

  const location = useLocation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const isValidPassword = (password: string) => {
    return ValidationUtils.validateCurrentPassword(password)
  }

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      password: e.target.value,
      passwordError: !isValidPassword(e.target.value),
      confirmPasswordError:
        !isValidConfirmPassword(state.confirmPassword, e.target.value) &&
        state.confirmPassword !== '',
    })
  }

  const isValidConfirmPassword = (
    confirmPassword: string,
    password: string
  ) => {
    return confirmPassword === password
  }

  const handleConfirmPasswordInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({
      ...state,
      confirmPassword: e.target.value,
      confirmPasswordError: !isValidConfirmPassword(
        e.target.value,
        state.password
      ),
    })
  }

  const handleResetPassword = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    const passwordError = !isValidPassword(state.password)
    const confirmPasswordError = !isValidConfirmPassword(
      state.confirmPassword,
      state.password
    )
    if (passwordError || confirmPasswordError) {
      setState((state) => {
        return {
          ...state,
          passwordError,
          confirmPasswordError,
        }
      })

      return
    }

    setState((state) => {
      return {
        ...state,
        isLoading: true,
      }
    })

    try {
      const resetPasswordToken = location.pathname.split('/')[2]

      const response = await ApiManager.makeApiCall(
        UrlConstants.RESET_PASSWORD.USECASE,
        HttpMethods.POST_METHOD,
        {
          $token: resetPasswordToken,
          newPassword: state.password,
        }
      ).catch((error) => {
        throw error
      })

      if (response.status === StatusCode.OK) {
        setState((state) => {
          return {
            ...state,
            isResetSuccess: true,
          }
        })
      } else if (response.status === StatusCode.BAD_REQUEST) {
        enqueueSnackbar(response.data.message, {
          variant: 'error',
        })
      } else {
        throw response
      }
    } catch (error) {
      enqueueSnackbar(StringConstants.INTERNAL_SERVER_ERROR, {
        variant: 'error',
      })
    } finally {
      setState((state) => {
        return {
          ...state,
          isLoading: false,
        }
      })
    }
  }

  return (
    <MainContainer>
      <Wrapper>
        <LogoWrapper>
          <img src={eipFlag} alt='eip flag' />
        </LogoWrapper>
        <Paper
          type='card'
          margin='20px 0px'
          padding='20px'
          boxShadow='0px 0px 12px -2px rgba(0, 0, 0, 0.05)'
        >
          <CardWrapper>
            {!state.isResetSuccess ? (
              <>
                <h2>Reset your password</h2>
                <Form>
                  <PasswordField
                    margin='20px 0px 0px 0px'
                    placeholder='new password'
                    superScript='Enter your new password'
                    onChange={handlePasswordInput}
                    value={state.password || ''}
                    fullWidth
                    error={state.passwordError}
                    helperText={
                      state.passwordError
                        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_PASSWORD
                        : ''
                    }
                  />

                  <PasswordField
                    placeholder='confirm password'
                    superScript='Confirm your new password'
                    onChange={handleConfirmPasswordInput}
                    value={state.confirmPassword || ''}
                    fullWidth
                    error={state.confirmPasswordError}
                    helperText={
                      state.confirmPasswordError
                        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_CONFIRM_PASSWORD
                        : ''
                    }
                  />

                  <Button
                    fullWidth
                    margin='20px 0px 0px 0px'
                    onClick={handleResetPassword}
                    loading={state.isLoading}
                    disabled={state.isLoading}
                  >
                    Reset Password
                  </Button>
                </Form>
              </>
            ) : (
              <>
                <SuccessContent>
                  <h3>
                    Your password reset was successful. <TickIcon />
                  </h3>
                </SuccessContent>

                <Button
                  fullWidth
                  color='primary'
                  endIcon={<AiOutlineRight />}
                  margin='20px 0px'
                  onClick={() => {
                    navigate(RouteConstants.LOGIN)
                  }}
                >
                  Back to Sign In page
                </Button>
              </>
            )}
          </CardWrapper>
        </Paper>
      </Wrapper>
    </MainContainer>
  )
}

export default ResetPassword
