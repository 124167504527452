import React from 'react'
import Paper from '../common/Paper/Paper'
import theme from '../../theme/theme'
import styled from '@emotion/styled'
import { AiOutlineHeart } from 'react-icons/ai'
import Button from '../common/Button/Button'
import { useWindowWidth } from '../../utils/hooks'
import { useNavigate } from 'react-router-dom'
import RouteConstants from '../../constants/RouteConstants'

const Wrapper = styled.div`
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px 20px;
  p {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    font-size: 14px;
    color: ${theme.colors.textDark};
  }
  @media screen and (max-width: 800px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: unset;
  }
`

const ReferralCard = () => {
  const isMobile = useWindowWidth()
  const navigate = useNavigate()
  return (
    <Paper borderColor={theme.colors.primary} borderRadius='4px'>
      <Wrapper>
        <p>
          <AiOutlineHeart /> Like what we did for you? Send a Referral!
        </p>
        <Button
          height='40px'
          fullWidth={isMobile}
          onClick={() => {
            navigate(RouteConstants.REFERRAL)
          }}
        >
          Go to Referrals
        </Button>
      </Wrapper>
    </Paper>
  )
}

export default ReferralCard
