import React from 'react'
import EnquiryModal from '../../components/common/Modal/EnquiryModal'
import styled from '@emotion/styled'
import MyPlanInfo from '../../components/MyPlans/MyPlanInfo'
import PlanCarousel from '../../components/MyPlans/PlanCarousel'
import NotCoveredCard from '../../components/MyPlans/NotCoveredCard'
import ReferralCard from '../../components/MyPlans/ReferralCard'
import PlanCard from '../../components/MyPlans/PlanCard'

const MainContainer = styled.div`
  display: flex;
  grid-gap: 50px;
  padding: 30px 0px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    grid-gap: 0px;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 800px;

  @media screen and (max-width: 1000px) {
    max-width: 100%;
  }
`

const MyPlans = () => {
  const [openEnquiryModal, setOpenEnquiryModal] = React.useState(true)
  return (
    <>
      <MainContainer>
        <MyPlanInfo />
        <ContentWrapper>
          <PlanCarousel />
          <PlanCard />
          <NotCoveredCard
            heading='Dental, Vision And Hearing'
            description='Save up to 90% on regular Dental, Vision and Hearing Costs'
          />
          <NotCoveredCard
            heading='Prescription Drugs Coverage'
            description='Save up to 90% on prescription drug coverage. This is just a placeholder description.'
          />
          <ReferralCard />
          <PlanCarousel />
        </ContentWrapper>
      </MainContainer>
      <EnquiryModal
        open={openEnquiryModal}
        handleClose={() => {
          setOpenEnquiryModal(false)
        }}
        heading='Hospital Indemnity Inquiry'
      />
    </>
  )
}

export default MyPlans
