import styled from '@emotion/styled'
import React from 'react'
import theme from '../../../theme/theme'
import { CgProfile } from 'react-icons/cg'
import { IoLogOutOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import RouteConstants from '../../../constants/RouteConstants'

interface UserDropdownProps {
  logout: () => void
}

const MainContainer = styled.div`
  min-width: 180px;
`

const ItemWrapper = styled.div`
  padding: 10px 20px;
  border-radius: 4px;
  color: ${theme.colors.primary};
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  transition: background-color 0.2s;
  &:hover {
    background-color: ${theme.colors.primaryBack2};
    transition: background-color 0.2s;
  }
  &:active {
    background-color: ${theme.colors.primaryBack};
    transition: background-color 0.2s;
  }
`
const BorderLine = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: ${theme.colors.borderColor};
  margin: 10px 0px;
`

const UserDropdown: React.FC<UserDropdownProps> = ({ logout }) => {
  const navigate = useNavigate()
  const handleLogout = () => {
    logout()
  }
  return (
    <MainContainer>
      <ItemWrapper
        tabIndex={0}
        onClick={() => {
          navigate(RouteConstants.PROFILE)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') navigate(RouteConstants.PROFILE)
        }}
      >
        <CgProfile style={{ fontSize: '24px' }} />
        <p>Profile</p>
      </ItemWrapper>
      <BorderLine />
      <ItemWrapper
        onClick={handleLogout}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleLogout()
        }}
      >
        <IoLogOutOutline style={{ fontSize: '24px' }} />
        <p>Logout</p>
      </ItemWrapper>
    </MainContainer>
  )
}

export default UserDropdown
