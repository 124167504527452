import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const testApiUrl = 'https://course-api.com/react-useReducer-cart-project'

interface TestState {
  value: number
  isLoading: boolean
  cartItems: Array<any>
}

const initialState = {
  value: 1,
  isLoading: false,
  cartItems: [],
} as TestState

export const getCartItems = createAsyncThunk('test/getCartItems', () => {
  return fetch(testApiUrl)
    .then((resp) => {
      return resp.json()
    })
    .catch((err) => console.log(err))
})

const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    increaseValue: (state) => {
      state.value++
    },
    decreaseValue: (state) => {
      state.value--
    },
    increaseValueBy: (state, action) => {
      state.value = state.value + action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCartItems.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCartItems.fulfilled, (state, action) => {
      console.log(action)
      state.cartItems = action.payload
      state.isLoading = false
    })
    builder.addCase(getCartItems.rejected, (state, action) => {
      state.isLoading = false
      state.cartItems = []
      console.log('oops, error occured')
    })
  },
})

export const { increaseValue, decreaseValue, increaseValueBy } =
  testSlice.actions

export default testSlice.reducer
