import styled from '@emotion/styled'
import React from 'react'
import theme from '../../theme/theme'
import { useWindowWidth } from '../../utils/hooks'
import Button from '../../components/common/Button/Button'
import { IoMdAddCircleOutline } from 'react-icons/io'
import ReferralInput from '../../components/Referral/ReferralInput'
import Paper from '../../components/common/Paper/Paper'
import { FiCopy } from 'react-icons/fi'
import { BsLink45Deg } from 'react-icons/bs'
import ReferralModal from '../../components/Referral/ReferralModal'

const MainContainer = styled.div``

const Section = styled.div`
  padding-bottom: 50px;
  .label {
    font-size: 14px;
    color: ${theme.colors.textDark};
  }
`

const Header = styled.h2`
  font-size: 18px;
  color: ${theme.colors.textDark};
  padding-bottom: 10px;
`

const Form = styled.form`
  padding: 20px 0px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0px 10px;

  .addAnotherReferral {
    padding: 10px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    font-size: 14px;
    cursor: pointer;
    color: ${theme.colors.primary};
    &:hover {
      text-decoration: underline;
      color: ${theme.colors.secondary};
    }
    &:active {
      color: ${theme.colors.primary};
    }
  }

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
  }
`

const CopyCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.primaryBack};
  }
  &:active {
    background-color: ${theme.colors.primaryBack2};
  }
  p {
    color: ${theme.colors.primary};
    font-size: 14px;
    text-decoration: underline;
    display: flex;
    align-items: center;
    grid-gap: 5px;
  }
`

const ReferSomeone = () => {
  const isMobile = useWindowWidth()
  const [isReferModalOpen, setIsReferModalOpen] = React.useState(false)

  const handleReferralSubmission = () => {
    setIsReferModalOpen(true)
  }

  return (
    <MainContainer>
      <Section>
        <Header>Refer your Friends and Family</Header>
        <p className='label'>
          Refer for great service for family and friends. We work Nationwide!
        </p>
      </Section>
      <Section>
        <Header>Send your referral</Header>
        <p className='label'>
          Enter the information for the person(s) you care to help.
        </p>
        <Form>
          <ReferralInput isMobile={isMobile} />
          <Row style={{ paddingTop: '20px' }}>
            <Button
              fullWidth={isMobile && true}
              onClick={handleReferralSubmission}
            >
              Submit
            </Button>
            <p className='addAnotherReferral'>
              <IoMdAddCircleOutline /> Add another referral
            </p>
          </Row>
        </Form>
      </Section>
      <Section>
        <Header>Or</Header>
        <p className='label'>Share this link with your loved one</p>

        <div style={{ maxWidth: '550px' }}>
          <Paper margin='10px 0px'>
            <CopyCard>
              <p>
                <BsLink45Deg style={{ fontSize: '18px' }} />{' '}
                https://referral.teameip.com/sd2f3-dfs21-9jvlnb
              </p>
              <FiCopy />
            </CopyCard>
          </Paper>
        </div>
      </Section>
      <ReferralModal
        open={isReferModalOpen}
        handleClose={() => {
          setIsReferModalOpen(false)
        }}
      />
    </MainContainer>
  )
}

export default ReferSomeone
