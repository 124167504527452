import styled from '@emotion/styled'
import { Autocomplete, TextField as MuiTextField } from '@mui/material'
import React from 'react'
import theme from '../../theme/theme'
import Paper from '../common/Paper/Paper'
import RadioGroup from '../common/RadioGroup/RadioGroup'
import InputField from '../common/Input/InputField'
import Button from '../common/Button/Button'

interface PrescriptionSearchFieldInterface {
  options: string[]
}

const TextField = styled(MuiTextField)({
  '.MuiOutlinedInput-root': {
    fontSize: '16px',
    borderRadius: '35px',
  },
  '.MuiOutlinedInput-input': {},
  '.MuiInputLabel-root': {
    fontSize: '16px',
  },
  '.MuiInputLabel-shrink': {
    marginInlineStart: '-4px',
  },
})

const Option = styled.li`
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  p {
    font-size: 14px;
    color: ${theme.colors.textDark};
    font-weight: 600;
  }
  &:hover {
    background-color: ${theme.colors.lightBack2};
  }
`

const AddPrescriptionDrugCardWrapper = styled.div`
  color: ${theme.colors.textDark};
  h3 {
    font-size: 20px;
  }
  .subheading {
    font-size: 12px;
    font-style: italic;
    padding-bottom: 20px;
  }
`

const PrescriptionSearchField: React.FC<PrescriptionSearchFieldInterface> = ({
  options,
}) => {
  const [drugLabel, setDrugLabel] = React.useState<string | null>(null)
  const [inputValue, setInputValue] = React.useState('')
  return (
    <>
      {drugLabel ? (
        <div style={{ maxWidth: '550px' }}>
          <Paper padding='20px' borderRadius='4px'>
            <AddPrescriptionDrugCardWrapper>
              <h3>{drugLabel}</h3>
              <p className='subheading'>Drug Brand</p>
              <RadioGroup
                groupLabel='Select Dose'
                radioButtons={[
                  {
                    value: '10mg',
                    label: 'Methadone hydrochloride 10mg oral tablet',
                  },
                  {
                    value: '5mg',
                    label: 'Methadone hydrochloride 5 oral tablet',
                  },
                ]}
                value=''
                onChange={(e) => {
                  console.log(e.target.value)
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '20px 0px',
                  gridGap: '5px',
                }}
              >
                <InputField
                  type='number'
                  placeholder='Add Quantity'
                  superScript='Add Quantity'
                />
                <InputField
                  type='number'
                  placeholder='Select Frequency'
                  superScript='Select Frequency'
                />
              </div>
              <Button
                fullWidth
                onClick={() => {
                  setDrugLabel(null)
                }}
              >
                Add Drug
              </Button>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => {
                  setDrugLabel(null)
                }}
                fullWidth
              >
                Cancel
              </Button>
            </AddPrescriptionDrugCardWrapper>
          </Paper>
        </div>
      ) : (
        <div>
          <Autocomplete
            value={drugLabel}
            onChange={(event: any, newValue: string | null) => {
              setDrugLabel(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            options={options}
            renderInput={(params) => (
              <TextField {...params} placeholder='Start typing drug name...' />
            )}
            renderOption={(props, option) => (
              <Option key={option} {...props}>
                <p>{option}</p>
              </Option>
            )}
          />
        </div>
      )}
    </>
  )
}

export default PrescriptionSearchField
