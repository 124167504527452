import styled from '@emotion/styled'
import React from 'react'
import theme from '../../theme/theme'

const Header = styled.h3`
  font-size: 18px;
  color: ${theme.colors.textDark};
  padding-bottom: 10px;
  font-weight: 700;
`

const Heading: React.FC<{ children: string }> = ({ children }) => {
  return <Header>{children}</Header>
}

export default Heading
